<p class="chat-text">
  Time to dream big! What's your ideal sound? Think genre, instruments, vibes. Name-drop some
  inspiring artists or bands. Don't hold back – we're here to make your unique sound shine!
</p>

<div class="chat-white-container">
  <input [(ngModel)]="promptValue" [readOnly]="!currentActiveComponent"
         [readonly]="!currentActiveComponent"
         maxlength="200"
         placeholder="Example: Modern country style, happy vibes"
         type="text">
  <button (click)="send()"
          [class.chat-button-generic-disabled]="!currentActiveComponent"
          class="gradient-purple-button sm:!w-1/5">
    Send
  </button>
</div>
