import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { UserStateService } from '../services/user-state.service';

export const ManagementGuard: CanActivateFn = (route, state) => {
  const userStateService = inject(UserStateService);
  const router = inject(Router);
  if (userStateService.user$.getValue()?.role === 'Admin') {
    return true;
  } else {
    router.navigate(['']);
    return false;
  }
};
