import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faFacebook, faInstagram, faTiktok, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import {Clipboard} from '@angular/cdk/clipboard';
import { VirtualQueueService } from '../../services/virtual-queue.service';
import { UserStateService } from '../../services/user-state.service';
import { ToastService } from '../../services/toast.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import html2canvas from 'html2canvas';
import { LoadingService } from '../../services/loading.service';
@UntilDestroy({checkProperties: true})
@Component({
  selector: 'sbz-virtual-queue',
  templateUrl: './virtual-queue.component.html',
  styleUrl: './virtual-queue.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VirtualQueueComponent implements OnInit {
  public userQueueNumber: number | undefined = 0;
  public totalQueueNumber: number = 0;
  public url: string = window.location.href;
  @ViewChild('queueBlock') queueBlock!: ElementRef;
  @ViewChild('canvas') canvas!: ElementRef;
  @ViewChild('downloadLink') downloadLink!: ElementRef;
  constructor(
    private library: FaIconLibrary, 
    private clipBoard: Clipboard,
    private virtualQueueService: VirtualQueueService,
    private cdr: ChangeDetectorRef,
    private userStateService: UserStateService,
    private toastService: ToastService,
    private loadingService: LoadingService
  ) {
    library.addIcons(faFacebook);
    library.addIcons(faInstagram);
    library.addIcons(faXTwitter);
    library.addIcons(faTiktok);
  }
  copyToClipBoard(input: HTMLInputElement) {
    this.clipBoard.copy(input.value);
    this.toastService.showToast('Link successfully copied!', 'success');
  }
  ngOnInit(): void {
    this.virtualQueueService.getTotalQeueu(this.userStateService.user$.getValue()?.token!).subscribe((res: {code: number, data: {totalQueue: string}, success: boolean}) => {
      this.totalQueueNumber = parseInt(res.data.totalQueue);
      this.userQueueNumber = this.userStateService.user$.getValue()?.queuePosition;
      this.cdr.detectChanges();
    });
  }
  downloadImage() {
    this.loadingService.isLoading$.next(true);
    html2canvas(this.queueBlock.nativeElement, { scale: 2}).then(canvas => {
      this.canvas.nativeElement.src = canvas.toDataURL();
      this.downloadLink.nativeElement.href = canvas.toDataURL('image/jpg');
      this.downloadLink.nativeElement.download = 'hitcraft-queue.jpg';
      this.downloadLink.nativeElement.click();
    }).then((res) => {
      this.loadingService.isLoading$.next(false);
    });
  }
}
