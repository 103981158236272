import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

import {ChatPlayerComponent} from './components/chat/chat-parts/chat-player/chat-player.component';

import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {HttpClientModule, provideHttpClient, withInterceptors} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {environment} from '../environments/environment.prod';
import {NgOptimizedImage} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DescopeAuthModule, DescopeAuthService, descopeInterceptor} from '@descope/angular-sdk';
import {DropzoneCdkModule} from '@ngx-dropzone/cdk';
import {NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule} from 'ngx-google-analytics';
import {LoginComponent} from './components/login/login.component';
import {HomeComponent} from './components/home/home.component';
import {NavbarComponent} from './components/navbar/navbar.component';
import {RobotComponent} from './components/robot/robot.component';
import {OutsideClickDirective} from './directives/outside-click.directive';
import {RegisterComponent} from './components/register/register.component';
import {FileUploadComponent} from './components/file-upload/file-upload.component';
import {SubscribeComponent} from './components/subscribe/subscribe.component';
import {FooterComponent} from './components/footer/footer.component';
import {ContactUsComponent} from './components/info-pages/contact-us/contact-us.component';
import {HelpAndSupportComponent} from './components/info-pages/help-and-support/help-and-support.component';
import {AboutUsComponent} from './components/info-pages/about-us/about-us.component';
import {ReadMoreComponent} from './components/info-pages/articles/read-more/read-more.component';
import {WelcomeComponent} from './components/welcome/welcome.component';
import {ErrorModalComponent} from './components/modal/error-modal/error-modal.component';
import {SafeHtmlPipe} from './pipes/safe-html.pipe';
import {CouponModalComponent} from './components/modal/coupon-modal/coupon-modal.component';
import {zip} from 'rxjs';
import {ChatContainerComponent} from './components/chat/chat-container/chat-container.component';
import {ChatWelcomeComponent} from './components/chat/chat-parts/chat-welcome/chat-welcome.component';
import {ChatStartSessionComponent} from './components/chat/chat-parts/chat-start-session/chat-start-session.component';
import {SystemChatBubbleComponent} from './components/chat/system-chat-bubble/system-chat-bubble.component';
import {PromptModalComponent} from './components/modal/prompt-modal/prompt-modal.component';
import {ChatPromptInputComponent} from './components/chat/chat-parts/chat-prompt-input/chat-prompt-input.component';

import { ChatLyricsInputComponent } from './components/chat/chat-parts/chat-lyrics-input/chat-lyrics-input.component';
import { ChatAfterLyricsComponent } from './components/chat/chat-parts/chat-after-lyrics/chat-after-lyrics.component';
import { VirtualQueueComponent } from './components/virtual-queue/virtual-queue.component';
import { ManagementQueueComponent } from './components/management-queue/management-queue.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { ToastComponent } from './components/toast/toast.component';

import {ChatJumpComponent} from './components/chat/chat-parts/chat-jump/chat-jump.component';
import {ChatSongNameComponent} from './components/chat/chat-parts/chat-song-name/chat-song-name.component';
import {ChatSongLoadingComponent} from './components/chat/chat-parts/chat-song-loading/chat-song-loading.component';
import {FadeOutSectionDirective} from './directives/fade-out-section.directive';
import { LandingComponent } from './components/landing/landing.component';

export function initializeApp(authService: DescopeAuthService) {
  return () => zip([authService.refreshSession(), authService.refreshUser()]);
}

@NgModule({
  declarations: [
    AppComponent,
    ChatPlayerComponent,
    LoginComponent,
    HomeComponent,
    NavbarComponent,
    RobotComponent,
    OutsideClickDirective,
    RegisterComponent,
    FileUploadComponent,
    SubscribeComponent,
    FooterComponent,
    ContactUsComponent,
    HelpAndSupportComponent,
    AboutUsComponent,
    ReadMoreComponent,
    WelcomeComponent,
    ErrorModalComponent,
    SafeHtmlPipe,
    CouponModalComponent,
    ChatContainerComponent,
    ChatWelcomeComponent,
    ChatStartSessionComponent,
    SystemChatBubbleComponent,
    PromptModalComponent,
    ChatPromptInputComponent,
    ChatLyricsInputComponent,
    ChatAfterLyricsComponent,
    VirtualQueueComponent,
    ManagementQueueComponent,
    PaginationComponent,
    ToastComponent,
    ChatJumpComponent,
    ChatSongNameComponent,
    ChatAfterLyricsComponent,
    ChatSongLoadingComponent,
    FadeOutSectionDirective,
    LandingComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FontAwesomeModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    DescopeAuthModule.forRoot({
      projectId: environment.descopeProjectId
    }),
    NgOptimizedImage,
    DropzoneCdkModule,
    ReactiveFormsModule,
    NgxGoogleAnalyticsModule.forRoot(environment.googleAnalyticsTrackingId),
    NgxGoogleAnalyticsRouterModule,
    FormsModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [DescopeAuthService],
      multi: true
    },
    provideHttpClient(withInterceptors([descopeInterceptor])),
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas);
  }
}
