import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {fadeTransition} from '../../../animations/fade-in-animation';
import {ChatWelcomeComponent} from '../chat-parts/chat-welcome/chat-welcome.component';
import {ChatStartSessionComponent} from '../chat-parts/chat-start-session/chat-start-session.component';
import {ComponentMap} from '../../../interfaces/component-map';
import {UntilDestroy} from '@ngneat/until-destroy';
import {ChatService} from '../../../services/chat.service';
import {ChatBubble} from '../../../interfaces/chat-bubble';
import {BehaviorSubject} from 'rxjs';
import {AudioService} from '../../../services/audio.service';
import {ChatPromptInputComponent} from '../chat-parts/chat-prompt-input/chat-prompt-input.component';
import {ChatPlayerComponent} from '../chat-parts/chat-player/chat-player.component';
import {ChatLyricsInputComponent} from '../chat-parts/chat-lyrics-input/chat-lyrics-input.component';
import {ChatJumpComponent} from '../chat-parts/chat-jump/chat-jump.component';
import {ChatSongNameComponent} from '../chat-parts/chat-song-name/chat-song-name.component';
import {ChatAfterLyricsComponent} from '../chat-parts/chat-after-lyrics/chat-after-lyrics.component';
import {ChatSongLoadingComponent} from '../chat-parts/chat-song-loading/chat-song-loading.component';
import {MobileDetectionService} from '../../../services/mobile-detection.service';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'sbz-chat-container',
  templateUrl: './chat-container.component.html',
  styleUrl: './chat-container.component.scss',
  animations: [fadeTransition]
})
export class ChatContainerComponent implements OnInit, OnDestroy {
  @ViewChild('chatTopOpacity', {read: ElementRef, static: true}) chatTopOpacity!: ElementRef;
  showStartNewChat$ = new BehaviorSubject(false);
  chatComponentsMap: ComponentMap = {
    'welcome': ChatWelcomeComponent,
    'jump': ChatJumpComponent,
    'start-session': ChatStartSessionComponent,
    'prompt-input': ChatPromptInputComponent,
    'song-name': ChatSongNameComponent,
    'lyrics-input': ChatLyricsInputComponent,
    'after-lyrics': ChatAfterLyricsComponent,
    'song-loading': ChatSongLoadingComponent,
    'player': ChatPlayerComponent,
  };
  chatBubbles: ChatBubble[] = [];

  private firstLoad = true;
  private stopOnScroll = false;

  constructor(private chatService: ChatService,
              private audioService: AudioService,
              private mobileDetectionService: MobileDetectionService,
              private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.chatService.currentlyActiveChatPart$.subscribe(componentName => {
      if (!this.stopOnScroll) {
        this.createChatBubble(componentName);
        if (this.firstLoad) {
          setTimeout(() => {
            this.chatService.currentlyActiveChatPart$.next('jump');
            this.cdr.markForCheck();
            this.cdr.detectChanges();
          }, 2500);
          this.firstLoad = false;
        }
      }
    });

    this.chatService.createUserBubbleWIthText$
      .subscribe(data => {
        if (data && !this.stopOnScroll) {
          this.createUserChatBubble(data);
          if (data.event === 'learn-more-produce') {
            setTimeout(() => {
              this.chatService.currentlyActiveChatPart$.next('ready-produce-learn');
              this.cdr.detectChanges();
            }, 2500);
          }
        }
      });
  }

  ngOnDestroy() {
    this.stopOnScroll = true;
    this.startNewChat();
  }

  startNewChat() {
    this.chatBubbles = [];
    this.firstLoad = true;
    this.chatService.createUserBubbleWIthText$.next(undefined);
    this.chatService.currentlyActiveChatPart$.next('welcome');
    this.audioService.stopSong();
  }

  private createUserChatBubble(data: { event: string; text: string }) {
    this.showStartNewChat$.next(true);
    this.chatBubbles.push({
      type: data.text,
      isLoading$: new BehaviorSubject<boolean>(true),
      isUserBubble: true
    });
    setTimeout(() => {
      this.createChatBubble(data.event);
      this.cdr.detectChanges();
    }, 300);
  }

  private createChatBubble(event: string) {
    if (!this.stopOnScroll) {
      const componentType = this.chatComponentsMap[event];
      this.chatBubbles.push({
        type: event,
        component: componentType,
        isLoading$: new BehaviorSubject<boolean>(true),
        isUserBubble: false
      });
      this.scrollToBottom();
      this.cdr.markForCheck();
      this.cdr.detectChanges();
      setTimeout(() => {
        this.chatBubbles[this.chatBubbles.length - 1].isLoading$.next(false);
        this.scrollToBottom();
      }, 1500);
    }
  }

  private scrollToBottom() {
    setTimeout(() => {
      if (this.chatBubbles.length < 3 && (this.mobileDetectionService.isMobile$.getValue())) {
        return;
      }

      if (this.mobileDetectionService.isMobile$.getValue()) {
        const mainContent = document.getElementById('main-content')!;
        mainContent.scrollTo({
          top: mainContent.scrollHeight,
          behavior: 'smooth'
        });
        return;
      }

      const chatContainer = document.getElementById('chat-container')!;
      chatContainer.scrollTo({
        top: chatContainer.scrollHeight,
        behavior: 'smooth'
      });
    }, 100);
  }
}
