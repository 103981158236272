import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {BaseChatComponent} from '../base.chat.component';
import {BehaviorSubject} from 'rxjs';
import {ModalService} from '../../../../services/modal.service';
import {ChatService} from '../../../../services/chat.service';
import {AudioService} from '../../../../services/audio.service';
import {UserStateService} from '../../../../services/user-state.service';

@Component({
  selector: 'sbz-chat-welcome',
  templateUrl: './chat-welcome.component.html',
  styleUrl: './chat-welcome.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatWelcomeComponent extends BaseChatComponent {
  currentlyPlayingSong = '';
  sketchIsPlaying$ = new BehaviorSubject(false);
  finalIsPlaying$ = new BehaviorSubject(false);

  private svgsAreHidden = false;

  constructor(modalService: ModalService,
              chatService: ChatService,
              audioService: AudioService,
              userStateService: UserStateService) {
    super(chatService, audioService, userStateService, modalService);
  }

  toggleSong(song: string) {
    if (!this.svgsAreHidden) {
      const hideSvg = document.querySelectorAll('#hideSvg');
      if (hideSvg.length > 0) {
        hideSvg.forEach(element => {
          element.classList.add('hidden');
        });
        this.svgsAreHidden = true;
        const showSvg = document.querySelectorAll('#showSvg');
        if (showSvg.length > 0) {
          showSvg.forEach(element => {
            element.classList.remove('hidden');
          });
        }
      }
    }
    switch (song) {
      case 'sketch':
        if (this.currentlyPlayingSong !== song) {
          this.audioService.playSong(song);
          this.currentlyPlayingSong = song;
          this.sketchIsPlaying$.next(true);
          this.finalIsPlaying$.next(false);
        } else {
          this.audioService.togglePlayPause();
          this.sketchIsPlaying$.next(!this.sketchIsPlaying$.getValue());
        }
        break;
      case 'final':
        if (this.currentlyPlayingSong !== song) {
          this.audioService.playSong(song);
          this.currentlyPlayingSong = song;
          this.sketchIsPlaying$.next(false);
          this.finalIsPlaying$.next(true);
        } else {
          this.audioService.togglePlayPause();
          this.finalIsPlaying$.next(!this.finalIsPlaying$.getValue());
        }
        break;
      case 'prompt':
        this.modalService.openPromptModal('"Produce the song in a modern country style song with acoustic guitar, light drums and piano"');
        // this.iframeLink = 'https://www.youtube.com/watch?v=SBjQ9tuuTJQ';
        // this.sketchIsPlaying$.next(false);
        // this.finalIsPlaying$.next(false);
        this.audioService.stopSong();
        break;
    }
  }
}
