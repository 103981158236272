import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '../../environments/environment.dev';
import { Observable } from 'rxjs';
import { ResolveFn } from '@angular/router';
import { QueueInfo } from '../interfaces/queue-Info';
import { UserStateService } from './user-state.service';


@Injectable({
  providedIn: 'root'
})
export class VirtualQueueService {
  constructor(
    private httpClient: HttpClient
  ) { }
  getTotalQeueu(userId: string): Observable<any>  {
    return this.httpClient.get(`${environment.baseUrl}/queue/${userId}/total`);
  }
  getQueueInfo(): Observable<any> {
    return this.httpClient.get(`${environment.baseUrl}/queue/info`);
  }
  getUserQueueInfo(): Observable<any> {
    return this.httpClient.get(`${environment.baseUrl}/queue/info`);
  }
  setQueueInfo(data: QueueInfo): Observable<any> {
    return this.httpClient.post(`${environment.baseUrl}/queue/info`, data);
  }
}

export const usersResolver: ResolveFn<Object> = (route, state) => {
  return inject(UserStateService).getUsersWhiteList();
}
