import * as i0 from '@angular/core';
import { Injectable, inject, EventEmitter, Component, Input, Output, NgModule, CUSTOM_ELEMENTS_SCHEMA, Optional, SkipSelf } from '@angular/core';
import createSdk from '@descope/web-js-sdk';
import { from, BehaviorSubject, tap, finalize, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { switchMap, catchError } from 'rxjs/operators';
import DescopeWebComponent from '@descope/web-component';
import DescopeUserManagementWidget from '@descope/user-management-widget';
import DescopeRoleManagementWidget from '@descope/role-management-widget';
import DescopeAccessKeyManagementWidget from '@descope/access-key-management-widget';
import DescopeAuditManagementWidget from '@descope/audit-management-widget';
import DescopeUserProfileWidget from '@descope/user-profile-widget';
const _c0 = ["flowId", ""];
const _c1 = ["tenant", ""];
function observabilify(value) {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const observableValue = {};
  for (const key in value) {
    if (typeof value[key] === 'function') {
      const fn = value[key];
      observableValue[key] = (...args) => {
        const fnResult = fn(...args);
        if (fnResult instanceof Promise) {
          return from(fnResult);
        } else {
          return fnResult;
        }
      };
    } else if (typeof value[key] === 'object' && value[key] !== null) {
      observableValue[key] = observabilify(value[key]);
    } else {
      observableValue[key] = value[key];
    }
  }
  return observableValue;
}
const environment = {
  buildVersion: '0.5.10'
};
const baseHeaders = {
  'x-descope-sdk-name': 'angular',
  'x-descope-sdk-version': environment.buildVersion
};
const isBrowser = () => typeof window !== 'undefined';
class DescopeAuthConfig {
  constructor() {
    this.projectId = '';
  }
}
class DescopeAuthService {
  constructor(config) {
    this.descopeSdk = observabilify(createSdk({
      persistTokens: isBrowser(),
      ...config,
      storeLastAuthenticatedUser: isBrowser(),
      autoRefresh: isBrowser(),
      baseHeaders
    }));
    this.sessionSubject = new BehaviorSubject({
      isAuthenticated: false,
      isSessionLoading: false,
      sessionToken: ''
    });
    this.session$ = this.sessionSubject.asObservable();
    this.userSubject = new BehaviorSubject({
      isUserLoading: false
    });
    this.user$ = this.userSubject.asObservable();
    this.descopeSdk.onSessionTokenChange(this.setSession.bind(this));
    this.descopeSdk.onUserChange(this.setUser.bind(this));
  }
  refreshSession() {
    const beforeRefreshSession = this.sessionSubject.value;
    this.sessionSubject.next({
      ...beforeRefreshSession,
      isSessionLoading: true
    });
    return this.descopeSdk.refresh().pipe(tap(data => {
      const afterRequestSession = this.sessionSubject.value;
      if (data.ok && data.data) {
        this.sessionSubject.next({
          ...afterRequestSession,
          sessionToken: data.data.sessionJwt,
          isAuthenticated: !!data.data.sessionJwt
        });
      } else {
        this.sessionSubject.next({
          ...afterRequestSession,
          sessionToken: '',
          isAuthenticated: false
        });
      }
    }), finalize(() => {
      const afterRefreshSession = this.sessionSubject.value;
      this.sessionSubject.next({
        ...afterRefreshSession,
        isSessionLoading: false
      });
    }));
  }
  refreshUser() {
    const beforeRefreshUser = this.userSubject.value;
    this.userSubject.next({
      ...beforeRefreshUser,
      isUserLoading: true
    });
    return this.descopeSdk.me().pipe(tap(data => {
      const afterRequestUser = this.userSubject.value;
      if (data.data) {
        this.userSubject.next({
          ...afterRequestUser,
          user: {
            ...data.data
          }
        });
      }
    }), finalize(() => {
      const afterRefreshUser = this.userSubject.value;
      this.userSubject.next({
        ...afterRefreshUser,
        isUserLoading: false
      });
    }));
  }
  getSessionToken() {
    if (isBrowser()) {
      return this.descopeSdk.getSessionToken();
    }
    console.warn('Get session token is not supported in SSR');
    return '';
  }
  getRefreshToken() {
    if (isBrowser()) {
      return this.descopeSdk.getRefreshToken();
    }
    this.descopeSdk.getJwtPermissions;
    console.warn('Get refresh token is not supported in SSR');
    return '';
  }
  isSessionTokenExpired(token = this.getSessionToken()) {
    if (isBrowser()) {
      return this.descopeSdk.isJwtExpired(token ?? '');
    }
    console.warn('isSessionTokenExpired is not supported in SSR');
    return true;
  }
  isRefreshTokenExpired(token = this.getRefreshToken()) {
    if (isBrowser()) {
      return this.descopeSdk.isJwtExpired(token ?? '');
    }
    console.warn('isRefreshTokenExpired is not supported in SSR');
    return true;
  }
  getJwtPermissions(token = this.getSessionToken(), tenant) {
    if (token === null) {
      console.error('Could not get JWT Permissions - not authenticated');
      return [];
    }
    return this.descopeSdk.getJwtPermissions(token, tenant);
  }
  getJwtRoles(token = this.getSessionToken(), tenant) {
    if (token === null) {
      console.error('Could not get JWT Roles - not authenticated');
      return [];
    }
    return this.descopeSdk.getJwtRoles(token, tenant);
  }
  isAuthenticated() {
    return this.sessionSubject.value.isAuthenticated;
  }
  setSession(sessionToken) {
    const currentSession = this.sessionSubject.value;
    this.sessionSubject.next({
      sessionToken,
      isAuthenticated: !!sessionToken,
      isSessionLoading: currentSession.isSessionLoading
    });
  }
  setUser(user) {
    const currentUser = this.userSubject.value;
    this.userSubject.next({
      isUserLoading: currentUser.isUserLoading,
      user
    });
  }
  static {
    this.ɵfac = function DescopeAuthService_Factory(t) {
      return new (t || DescopeAuthService)(i0.ɵɵinject(DescopeAuthConfig));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: DescopeAuthService,
      factory: DescopeAuthService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DescopeAuthService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: DescopeAuthConfig
    }];
  }, null);
})();
const descopeAuthGuard = route => {
  const authService = inject(DescopeAuthService);
  const router = inject(Router);
  const fallbackUrl = route.data['descopeFallbackUrl'];
  const isAuthenticated = authService.isAuthenticated();
  if (!isAuthenticated && !!fallbackUrl) {
    return from(router.navigate([fallbackUrl]));
  }
  return of(isAuthenticated);
};
const descopeInterceptor = (request, next) => {
  const config = inject(DescopeAuthConfig);
  const authService = inject(DescopeAuthService);
  function refreshAndRetry(request, next, error) {
    return authService.refreshSession().pipe(switchMap(refreshed => {
      if (refreshed.ok && refreshed.data) {
        const requestWithRefreshedToken = addTokenToRequest(request, refreshed.data?.sessionJwt);
        return next(requestWithRefreshedToken);
      } else {
        return throwError(() => error ?? new Error('Could not refresh session!'));
      }
    }));
  }
  function shouldIntercept(request) {
    return (config.pathsToIntercept?.length === 0 || config.pathsToIntercept?.some(path => request.url.includes(path))) ?? true;
  }
  function addTokenToRequest(request, token) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }
  if (shouldIntercept(request)) {
    const token = authService.getSessionToken();
    if (!token) {
      return refreshAndRetry(request, next);
    }
    const requestWithToken = addTokenToRequest(request, token);
    return next(requestWithToken).pipe(catchError(error => {
      if (error.status === 401 || error.status === 403) {
        return refreshAndRetry(request, next, error);
      } else {
        return throwError(() => error);
      }
    }));
  } else {
    return next(request);
  }
};
class DescopeComponent {
  constructor(elementRef, authService, descopeConfig) {
    this.elementRef = elementRef;
    this.authService = authService;
    this.success = new EventEmitter();
    this.error = new EventEmitter();
    this.ready = new EventEmitter();
    this.webComponent = new DescopeWebComponent();
    this.projectId = descopeConfig.projectId;
    this.baseUrl = descopeConfig.baseUrl;
    this.baseStaticUrl = descopeConfig.baseStaticUrl;
    this.storeLastAuthenticatedUser = descopeConfig.storeLastAuthenticatedUser;
  }
  ngOnInit() {
    const sdk = this.authService.descopeSdk; // Capture the class context in a variable
    DescopeWebComponent.sdkConfigOverrides = {
      // Overrides the web-component's base headers to indicate usage via the React SDK
      baseHeaders,
      // Disables token persistence within the web-component to delegate token management
      // to the global SDK hooks. This ensures token handling aligns with the SDK's configuration,
      // and web-component requests leverage the global SDK's beforeRequest hooks for consistency
      persistTokens: false,
      hooks: {
        get beforeRequest() {
          // Retrieves the beforeRequest hook from the global SDK, which is initialized
          // within the AuthProvider using the desired configuration. This approach ensures
          // the web-component utilizes the same beforeRequest hooks as the global SDK
          return sdk.httpClient.hooks?.beforeRequest;
        },
        set beforeRequest(_) {
          // The empty setter prevents runtime errors when attempts are made to assign a value to 'beforeRequest'.
          // JavaScript objects default to having both getters and setters
        }
      }
    };
    this.setupWebComponent();
    this.elementRef.nativeElement.appendChild(this.webComponent);
  }
  ngOnChanges() {
    this.setupWebComponent();
  }
  setupWebComponent() {
    this.webComponent.setAttribute('project-id', this.projectId);
    this.webComponent.setAttribute('flow-id', this.flowId);
    if (this.baseUrl) {
      this.webComponent.setAttribute('base-url', this.baseUrl);
    }
    if (this.baseStaticUrl) {
      this.webComponent.setAttribute('base-static-url', this.baseStaticUrl);
    }
    if (this.storeLastAuthenticatedUser) {
      this.webComponent.setAttribute('store-last-authenticated-user', this.storeLastAuthenticatedUser.toString());
    }
    if (this.locale) {
      this.webComponent.setAttribute('locale', this.locale);
    }
    if (this.theme) {
      this.webComponent.setAttribute('theme', this.theme);
    }
    if (this.tenant) {
      this.webComponent.setAttribute('tenant', this.tenant);
    }
    if (this.telemetryKey) {
      this.webComponent.setAttribute('telemetryKey', this.telemetryKey);
    }
    if (this.redirectUrl) {
      this.webComponent.setAttribute('redirect-url', this.redirectUrl);
    }
    if (this.autoFocus) {
      this.webComponent.setAttribute('auto-focus', this.autoFocus.toString());
    }
    if (this.debug) {
      this.webComponent.setAttribute('debug', this.debug.toString());
    }
    if (this.errorTransformer) {
      this.webComponent.errorTransformer = this.errorTransformer;
    }
    if (this.client) {
      this.webComponent.setAttribute('client', JSON.stringify(this.client));
    }
    if (this.form) {
      this.webComponent.setAttribute('form', JSON.stringify(this.form));
    }
    if (this.logger) {
      this.webComponent.logger = this.logger;
    }
    this.webComponent.addEventListener('success', e => {
      from(this.authService.descopeSdk.httpClient.hooks?.afterRequest(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      {}, new Response(JSON.stringify(e.detail)))).subscribe(() => {
        if (this.success) {
          this.success?.emit(e);
        }
      });
    });
    if (this.error) {
      this.webComponent.addEventListener('error', e => {
        this.error?.emit(e);
      });
    }
    if (this.ready) {
      this.webComponent.addEventListener('ready', () => {
        this.ready?.emit();
      });
    }
  }
  static {
    this.ɵfac = function DescopeComponent_Factory(t) {
      return new (t || DescopeComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(DescopeAuthService), i0.ɵɵdirectiveInject(DescopeAuthConfig));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DescopeComponent,
      selectors: [["descope", "flowId", ""]],
      inputs: {
        flowId: "flowId",
        locale: "locale",
        theme: "theme",
        tenant: "tenant",
        telemetryKey: "telemetryKey",
        redirectUrl: "redirectUrl",
        autoFocus: "autoFocus",
        debug: "debug",
        errorTransformer: "errorTransformer",
        client: "client",
        form: "form",
        logger: "logger"
      },
      outputs: {
        success: "success",
        error: "error",
        ready: "ready"
      },
      standalone: true,
      features: [i0.ɵɵNgOnChangesFeature, i0.ɵɵStandaloneFeature],
      attrs: _c0,
      decls: 0,
      vars: 0,
      template: function DescopeComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DescopeComponent, [{
    type: Component,
    args: [{
      selector: 'descope[flowId]',
      standalone: true,
      template: ''
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: DescopeAuthService
    }, {
      type: DescopeAuthConfig
    }];
  }, {
    flowId: [{
      type: Input
    }],
    locale: [{
      type: Input
    }],
    theme: [{
      type: Input
    }],
    tenant: [{
      type: Input
    }],
    telemetryKey: [{
      type: Input
    }],
    redirectUrl: [{
      type: Input
    }],
    autoFocus: [{
      type: Input
    }],
    debug: [{
      type: Input
    }],
    errorTransformer: [{
      type: Input
    }],
    client: [{
      type: Input
    }],
    form: [{
      type: Input
    }],
    logger: [{
      type: Input
    }],
    success: [{
      type: Output
    }],
    error: [{
      type: Output
    }],
    ready: [{
      type: Output
    }]
  });
})();
class SignInFlowComponent {
  constructor(descopeConfig) {
    this.success = new EventEmitter();
    this.error = new EventEmitter();
    this.projectId = descopeConfig.projectId;
  }
  static {
    this.ɵfac = function SignInFlowComponent_Factory(t) {
      return new (t || SignInFlowComponent)(i0.ɵɵdirectiveInject(DescopeAuthConfig));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SignInFlowComponent,
      selectors: [["descope-sign-in-flow"]],
      inputs: {
        locale: "locale",
        theme: "theme",
        tenant: "tenant",
        telemetryKey: "telemetryKey",
        redirectUrl: "redirectUrl",
        autoFocus: "autoFocus",
        debug: "debug",
        errorTransformer: "errorTransformer",
        client: "client",
        form: "form",
        logger: "logger"
      },
      outputs: {
        success: "success",
        error: "error"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 1,
      vars: 11,
      consts: [["flowId", "sign-in", 3, "success", "error", "locale", "theme", "tenant", "telemetryKey", "redirectUrl", "autoFocus", "debug", "errorTransformer", "client", "form", "logger"]],
      template: function SignInFlowComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "descope", 0);
          i0.ɵɵlistener("success", function SignInFlowComponent_Template_descope_success_0_listener($event) {
            return ctx.success.emit($event);
          })("error", function SignInFlowComponent_Template_descope_error_0_listener($event) {
            return ctx.error.emit($event);
          });
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("locale", ctx.locale)("theme", ctx.theme)("tenant", ctx.tenant)("telemetryKey", ctx.telemetryKey)("redirectUrl", ctx.redirectUrl)("autoFocus", ctx.autoFocus)("debug", ctx.debug)("errorTransformer", ctx.errorTransformer)("client", ctx.client)("form", ctx.form)("logger", ctx.logger);
        }
      },
      dependencies: [DescopeComponent],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SignInFlowComponent, [{
    type: Component,
    args: [{
      selector: 'descope-sign-in-flow',
      standalone: true,
      imports: [DescopeComponent],
      template: "<descope\n\t(success)=\"success.emit($event)\"\n\t(error)=\"error.emit($event)\"\n\tflowId=\"sign-in\"\n\t[locale]=\"locale\"\n\t[theme]=\"theme\"\n\t[tenant]=\"tenant\"\n\t[telemetryKey]=\"telemetryKey\"\n\t[redirectUrl]=\"redirectUrl\"\n\t[autoFocus]=\"autoFocus\"\n\t[debug]=\"debug\"\n\t[errorTransformer]=\"errorTransformer\"\n\t[client]=\"client\"\n\t[form]=\"form\"\n\t[logger]=\"logger\"\n>\n</descope>\n"
    }]
  }], function () {
    return [{
      type: DescopeAuthConfig
    }];
  }, {
    locale: [{
      type: Input
    }],
    theme: [{
      type: Input
    }],
    tenant: [{
      type: Input
    }],
    telemetryKey: [{
      type: Input
    }],
    redirectUrl: [{
      type: Input
    }],
    autoFocus: [{
      type: Input
    }],
    debug: [{
      type: Input
    }],
    errorTransformer: [{
      type: Input
    }],
    client: [{
      type: Input
    }],
    form: [{
      type: Input
    }],
    logger: [{
      type: Input
    }],
    success: [{
      type: Output
    }],
    error: [{
      type: Output
    }]
  });
})();
class SignUpFlowComponent {
  constructor(descopeConfig) {
    this.success = new EventEmitter();
    this.error = new EventEmitter();
    this.projectId = descopeConfig.projectId;
  }
  static {
    this.ɵfac = function SignUpFlowComponent_Factory(t) {
      return new (t || SignUpFlowComponent)(i0.ɵɵdirectiveInject(DescopeAuthConfig));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SignUpFlowComponent,
      selectors: [["descope-sign-up-flow"]],
      inputs: {
        locale: "locale",
        theme: "theme",
        tenant: "tenant",
        telemetryKey: "telemetryKey",
        redirectUrl: "redirectUrl",
        autoFocus: "autoFocus",
        debug: "debug",
        errorTransformer: "errorTransformer",
        client: "client",
        form: "form",
        logger: "logger"
      },
      outputs: {
        success: "success",
        error: "error"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 1,
      vars: 11,
      consts: [["flowId", "sign-up", 3, "success", "error", "locale", "theme", "tenant", "telemetryKey", "redirectUrl", "autoFocus", "debug", "errorTransformer", "client", "form", "logger"]],
      template: function SignUpFlowComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "descope", 0);
          i0.ɵɵlistener("success", function SignUpFlowComponent_Template_descope_success_0_listener($event) {
            return ctx.success.emit($event);
          })("error", function SignUpFlowComponent_Template_descope_error_0_listener($event) {
            return ctx.error.emit($event);
          });
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("locale", ctx.locale)("theme", ctx.theme)("tenant", ctx.tenant)("telemetryKey", ctx.telemetryKey)("redirectUrl", ctx.redirectUrl)("autoFocus", ctx.autoFocus)("debug", ctx.debug)("errorTransformer", ctx.errorTransformer)("client", ctx.client)("form", ctx.form)("logger", ctx.logger);
        }
      },
      dependencies: [DescopeComponent],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SignUpFlowComponent, [{
    type: Component,
    args: [{
      selector: 'descope-sign-up-flow',
      standalone: true,
      imports: [DescopeComponent],
      template: "<descope\n\t(success)=\"success.emit($event)\"\n\t(error)=\"error.emit($event)\"\n\tflowId=\"sign-up\"\n\t[locale]=\"locale\"\n\t[theme]=\"theme\"\n\t[tenant]=\"tenant\"\n\t[telemetryKey]=\"telemetryKey\"\n\t[redirectUrl]=\"redirectUrl\"\n\t[autoFocus]=\"autoFocus\"\n\t[debug]=\"debug\"\n\t[errorTransformer]=\"errorTransformer\"\n\t[client]=\"client\"\n\t[form]=\"form\"\n\t[logger]=\"logger\"\n>\n</descope>\n"
    }]
  }], function () {
    return [{
      type: DescopeAuthConfig
    }];
  }, {
    locale: [{
      type: Input
    }],
    theme: [{
      type: Input
    }],
    tenant: [{
      type: Input
    }],
    telemetryKey: [{
      type: Input
    }],
    redirectUrl: [{
      type: Input
    }],
    autoFocus: [{
      type: Input
    }],
    debug: [{
      type: Input
    }],
    errorTransformer: [{
      type: Input
    }],
    client: [{
      type: Input
    }],
    form: [{
      type: Input
    }],
    logger: [{
      type: Input
    }],
    success: [{
      type: Output
    }],
    error: [{
      type: Output
    }]
  });
})();
class SignUpOrInFlowComponent {
  constructor(descopeConfig) {
    this.success = new EventEmitter();
    this.error = new EventEmitter();
    this.projectId = descopeConfig.projectId;
  }
  static {
    this.ɵfac = function SignUpOrInFlowComponent_Factory(t) {
      return new (t || SignUpOrInFlowComponent)(i0.ɵɵdirectiveInject(DescopeAuthConfig));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SignUpOrInFlowComponent,
      selectors: [["descope-sign-up-or-in-flow"]],
      inputs: {
        locale: "locale",
        theme: "theme",
        tenant: "tenant",
        telemetryKey: "telemetryKey",
        redirectUrl: "redirectUrl",
        autoFocus: "autoFocus",
        debug: "debug",
        errorTransformer: "errorTransformer",
        client: "client",
        form: "form",
        logger: "logger"
      },
      outputs: {
        success: "success",
        error: "error"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 1,
      vars: 11,
      consts: [["flowId", "sign-up-or-in", 3, "success", "error", "locale", "theme", "tenant", "telemetryKey", "redirectUrl", "autoFocus", "debug", "errorTransformer", "client", "form", "logger"]],
      template: function SignUpOrInFlowComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "descope", 0);
          i0.ɵɵlistener("success", function SignUpOrInFlowComponent_Template_descope_success_0_listener($event) {
            return ctx.success.emit($event);
          })("error", function SignUpOrInFlowComponent_Template_descope_error_0_listener($event) {
            return ctx.error.emit($event);
          });
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("locale", ctx.locale)("theme", ctx.theme)("tenant", ctx.tenant)("telemetryKey", ctx.telemetryKey)("redirectUrl", ctx.redirectUrl)("autoFocus", ctx.autoFocus)("debug", ctx.debug)("errorTransformer", ctx.errorTransformer)("client", ctx.client)("form", ctx.form)("logger", ctx.logger);
        }
      },
      dependencies: [DescopeComponent],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SignUpOrInFlowComponent, [{
    type: Component,
    args: [{
      selector: 'descope-sign-up-or-in-flow',
      standalone: true,
      imports: [DescopeComponent],
      template: "<descope\n\t(success)=\"success.emit($event)\"\n\t(error)=\"error.emit($event)\"\n\tflowId=\"sign-up-or-in\"\n\t[locale]=\"locale\"\n\t[theme]=\"theme\"\n\t[tenant]=\"tenant\"\n\t[telemetryKey]=\"telemetryKey\"\n\t[redirectUrl]=\"redirectUrl\"\n\t[autoFocus]=\"autoFocus\"\n\t[debug]=\"debug\"\n\t[errorTransformer]=\"errorTransformer\"\n\t[client]=\"client\"\n\t[form]=\"form\"\n\t[logger]=\"logger\"\n>\n</descope>\n"
    }]
  }], function () {
    return [{
      type: DescopeAuthConfig
    }];
  }, {
    locale: [{
      type: Input
    }],
    theme: [{
      type: Input
    }],
    tenant: [{
      type: Input
    }],
    telemetryKey: [{
      type: Input
    }],
    redirectUrl: [{
      type: Input
    }],
    autoFocus: [{
      type: Input
    }],
    debug: [{
      type: Input
    }],
    errorTransformer: [{
      type: Input
    }],
    client: [{
      type: Input
    }],
    form: [{
      type: Input
    }],
    logger: [{
      type: Input
    }],
    success: [{
      type: Output
    }],
    error: [{
      type: Output
    }]
  });
})();
class UserManagementComponent {
  constructor(elementRef, descopeConfig) {
    this.elementRef = elementRef;
    this.webComponent = new DescopeUserManagementWidget();
    this.projectId = descopeConfig.projectId;
    this.baseUrl = descopeConfig.baseUrl;
    this.baseStaticUrl = descopeConfig.baseStaticUrl;
  }
  ngOnInit() {
    this.setupWebComponent();
    this.elementRef.nativeElement.appendChild(this.webComponent);
  }
  ngOnChanges() {
    this.setupWebComponent();
  }
  setupWebComponent() {
    this.webComponent.setAttribute('project-id', this.projectId);
    this.webComponent.setAttribute('tenant', this.tenant);
    this.webComponent.setAttribute('widget-id', this.widgetId);
    if (this.baseUrl) {
      this.webComponent.setAttribute('base-url', this.baseUrl);
    }
    if (this.baseStaticUrl) {
      this.webComponent.setAttribute('base-static-url', this.baseStaticUrl);
    }
    if (this.theme) {
      this.webComponent.setAttribute('theme', this.theme);
    }
    if (this.debug) {
      this.webComponent.setAttribute('debug', this.debug.toString());
    }
    if (this.logger) {
      this.webComponent.logger = this.logger;
    }
  }
  static {
    this.ɵfac = function UserManagementComponent_Factory(t) {
      return new (t || UserManagementComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(DescopeAuthConfig));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: UserManagementComponent,
      selectors: [["user-management", "tenant", ""]],
      inputs: {
        tenant: "tenant",
        widgetId: "widgetId",
        theme: "theme",
        debug: "debug",
        logger: "logger"
      },
      standalone: true,
      features: [i0.ɵɵNgOnChangesFeature, i0.ɵɵStandaloneFeature],
      attrs: _c1,
      decls: 0,
      vars: 0,
      template: function UserManagementComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UserManagementComponent, [{
    type: Component,
    args: [{
      selector: 'user-management[tenant]',
      standalone: true,
      template: ''
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: DescopeAuthConfig
    }];
  }, {
    tenant: [{
      type: Input
    }],
    widgetId: [{
      type: Input
    }],
    theme: [{
      type: Input
    }],
    debug: [{
      type: Input
    }],
    logger: [{
      type: Input
    }]
  });
})();
class RoleManagementComponent {
  constructor(elementRef, descopeConfig) {
    this.elementRef = elementRef;
    this.webComponent = new DescopeRoleManagementWidget();
    this.projectId = descopeConfig.projectId;
    this.baseUrl = descopeConfig.baseUrl;
    this.baseStaticUrl = descopeConfig.baseStaticUrl;
  }
  ngOnInit() {
    this.setupWebComponent();
    this.elementRef.nativeElement.appendChild(this.webComponent);
  }
  ngOnChanges() {
    this.setupWebComponent();
  }
  setupWebComponent() {
    this.webComponent.setAttribute('project-id', this.projectId);
    this.webComponent.setAttribute('tenant', this.tenant);
    this.webComponent.setAttribute('widget-id', this.widgetId);
    if (this.baseUrl) {
      this.webComponent.setAttribute('base-url', this.baseUrl);
    }
    if (this.baseStaticUrl) {
      this.webComponent.setAttribute('base-static-url', this.baseStaticUrl);
    }
    if (this.theme) {
      this.webComponent.setAttribute('theme', this.theme);
    }
    if (this.debug) {
      this.webComponent.setAttribute('debug', this.debug.toString());
    }
    if (this.logger) {
      this.webComponent.logger = this.logger;
    }
  }
  static {
    this.ɵfac = function RoleManagementComponent_Factory(t) {
      return new (t || RoleManagementComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(DescopeAuthConfig));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: RoleManagementComponent,
      selectors: [["role-management", "tenant", ""]],
      inputs: {
        tenant: "tenant",
        widgetId: "widgetId",
        theme: "theme",
        debug: "debug",
        logger: "logger"
      },
      standalone: true,
      features: [i0.ɵɵNgOnChangesFeature, i0.ɵɵStandaloneFeature],
      attrs: _c1,
      decls: 0,
      vars: 0,
      template: function RoleManagementComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RoleManagementComponent, [{
    type: Component,
    args: [{
      selector: 'role-management[tenant]',
      standalone: true,
      template: ''
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: DescopeAuthConfig
    }];
  }, {
    tenant: [{
      type: Input
    }],
    widgetId: [{
      type: Input
    }],
    theme: [{
      type: Input
    }],
    debug: [{
      type: Input
    }],
    logger: [{
      type: Input
    }]
  });
})();
class AccessKeyManagementComponent {
  constructor(elementRef, descopeConfig) {
    this.elementRef = elementRef;
    this.webComponent = new DescopeAccessKeyManagementWidget();
    this.projectId = descopeConfig.projectId;
    this.baseUrl = descopeConfig.baseUrl;
    this.baseStaticUrl = descopeConfig.baseStaticUrl;
  }
  ngOnInit() {
    this.setupWebComponent();
    this.elementRef.nativeElement.appendChild(this.webComponent);
  }
  ngOnChanges() {
    this.setupWebComponent();
  }
  setupWebComponent() {
    this.webComponent.setAttribute('project-id', this.projectId);
    this.webComponent.setAttribute('tenant', this.tenant);
    this.webComponent.setAttribute('widget-id', this.widgetId);
    if (this.baseUrl) {
      this.webComponent.setAttribute('base-url', this.baseUrl);
    }
    if (this.baseStaticUrl) {
      this.webComponent.setAttribute('base-static-url', this.baseStaticUrl);
    }
    if (this.theme) {
      this.webComponent.setAttribute('theme', this.theme);
    }
    if (this.debug) {
      this.webComponent.setAttribute('debug', this.debug.toString());
    }
    if (this.logger) {
      this.webComponent.logger = this.logger;
    }
  }
  static {
    this.ɵfac = function AccessKeyManagementComponent_Factory(t) {
      return new (t || AccessKeyManagementComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(DescopeAuthConfig));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: AccessKeyManagementComponent,
      selectors: [["access-key-management", "tenant", ""]],
      inputs: {
        tenant: "tenant",
        widgetId: "widgetId",
        theme: "theme",
        debug: "debug",
        logger: "logger"
      },
      standalone: true,
      features: [i0.ɵɵNgOnChangesFeature, i0.ɵɵStandaloneFeature],
      attrs: _c1,
      decls: 0,
      vars: 0,
      template: function AccessKeyManagementComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AccessKeyManagementComponent, [{
    type: Component,
    args: [{
      selector: 'access-key-management[tenant]',
      standalone: true,
      template: ''
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: DescopeAuthConfig
    }];
  }, {
    tenant: [{
      type: Input
    }],
    widgetId: [{
      type: Input
    }],
    theme: [{
      type: Input
    }],
    debug: [{
      type: Input
    }],
    logger: [{
      type: Input
    }]
  });
})();
class AuditManagementComponent {
  constructor(elementRef, descopeConfig) {
    this.elementRef = elementRef;
    this.webComponent = new DescopeAuditManagementWidget();
    this.projectId = descopeConfig.projectId;
    this.baseUrl = descopeConfig.baseUrl;
    this.baseStaticUrl = descopeConfig.baseStaticUrl;
  }
  ngOnInit() {
    this.setupWebComponent();
    this.elementRef.nativeElement.appendChild(this.webComponent);
  }
  ngOnChanges() {
    this.setupWebComponent();
  }
  setupWebComponent() {
    this.webComponent.setAttribute('project-id', this.projectId);
    this.webComponent.setAttribute('tenant', this.tenant);
    this.webComponent.setAttribute('widget-id', this.widgetId);
    if (this.baseUrl) {
      this.webComponent.setAttribute('base-url', this.baseUrl);
    }
    if (this.baseStaticUrl) {
      this.webComponent.setAttribute('base-static-url', this.baseStaticUrl);
    }
    if (this.theme) {
      this.webComponent.setAttribute('theme', this.theme);
    }
    if (this.debug) {
      this.webComponent.setAttribute('debug', this.debug.toString());
    }
    if (this.logger) {
      this.webComponent.logger = this.logger;
    }
  }
  static {
    this.ɵfac = function AuditManagementComponent_Factory(t) {
      return new (t || AuditManagementComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(DescopeAuthConfig));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: AuditManagementComponent,
      selectors: [["audit-management", "tenant", ""]],
      inputs: {
        tenant: "tenant",
        widgetId: "widgetId",
        theme: "theme",
        debug: "debug",
        logger: "logger"
      },
      standalone: true,
      features: [i0.ɵɵNgOnChangesFeature, i0.ɵɵStandaloneFeature],
      attrs: _c1,
      decls: 0,
      vars: 0,
      template: function AuditManagementComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AuditManagementComponent, [{
    type: Component,
    args: [{
      selector: 'audit-management[tenant]',
      standalone: true,
      template: ''
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: DescopeAuthConfig
    }];
  }, {
    tenant: [{
      type: Input
    }],
    widgetId: [{
      type: Input
    }],
    theme: [{
      type: Input
    }],
    debug: [{
      type: Input
    }],
    logger: [{
      type: Input
    }]
  });
})();
class UserProfileComponent {
  constructor(elementRef, descopeConfig) {
    this.elementRef = elementRef;
    this.logout = new EventEmitter();
    this.webComponent = new DescopeUserProfileWidget();
    this.projectId = descopeConfig.projectId;
    this.baseUrl = descopeConfig.baseUrl;
    this.baseStaticUrl = descopeConfig.baseStaticUrl;
  }
  ngOnInit() {
    this.setupWebComponent();
    this.elementRef.nativeElement.appendChild(this.webComponent);
  }
  ngOnChanges() {
    this.setupWebComponent();
  }
  setupWebComponent() {
    this.webComponent.setAttribute('project-id', this.projectId);
    this.webComponent.setAttribute('widget-id', this.widgetId);
    if (this.baseUrl) {
      this.webComponent.setAttribute('base-url', this.baseUrl);
    }
    if (this.baseStaticUrl) {
      this.webComponent.setAttribute('base-static-url', this.baseStaticUrl);
    }
    if (this.theme) {
      this.webComponent.setAttribute('theme', this.theme);
    }
    if (this.debug) {
      this.webComponent.setAttribute('debug', this.debug.toString());
    }
    if (this.logger) {
      this.webComponent.logger = this.logger;
    }
    if (this.logout) {
      this.webComponent.addEventListener('logout', e => {
        this.logout?.emit(e);
      });
    }
  }
  static {
    this.ɵfac = function UserProfileComponent_Factory(t) {
      return new (t || UserProfileComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(DescopeAuthConfig));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: UserProfileComponent,
      selectors: [["user-profile"]],
      inputs: {
        widgetId: "widgetId",
        theme: "theme",
        debug: "debug",
        logger: "logger"
      },
      outputs: {
        logout: "logout"
      },
      standalone: true,
      features: [i0.ɵɵNgOnChangesFeature, i0.ɵɵStandaloneFeature],
      decls: 0,
      vars: 0,
      template: function UserProfileComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UserProfileComponent, [{
    type: Component,
    args: [{
      selector: 'user-profile',
      standalone: true,
      template: ''
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: DescopeAuthConfig
    }];
  }, {
    widgetId: [{
      type: Input
    }],
    theme: [{
      type: Input
    }],
    debug: [{
      type: Input
    }],
    logger: [{
      type: Input
    }],
    logout: [{
      type: Output
    }]
  });
})();
class DescopeAuthModule {
  constructor(parentModule) {
    if (parentModule) {
      throw new Error('DescopeAuthModule is already loaded. Import it only once');
    }
  }
  static forRoot(config) {
    return {
      ngModule: DescopeAuthModule,
      providers: [{
        provide: DescopeAuthConfig,
        useValue: config
      }]
    };
  }
  static {
    this.ɵfac = function DescopeAuthModule_Factory(t) {
      return new (t || DescopeAuthModule)(i0.ɵɵinject(DescopeAuthModule, 12));
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: DescopeAuthModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DescopeAuthModule, [{
    type: NgModule,
    args: [{
      schemas: [CUSTOM_ELEMENTS_SCHEMA],
      imports: [DescopeComponent, SignInFlowComponent, SignUpFlowComponent, SignUpOrInFlowComponent, UserManagementComponent, RoleManagementComponent, AccessKeyManagementComponent, AuditManagementComponent, UserProfileComponent],
      exports: [DescopeComponent, SignInFlowComponent, SignUpFlowComponent, SignUpOrInFlowComponent, UserManagementComponent, RoleManagementComponent, AccessKeyManagementComponent, AuditManagementComponent, UserProfileComponent]
    }]
  }], function () {
    return [{
      type: DescopeAuthModule,
      decorators: [{
        type: Optional
      }, {
        type: SkipSelf
      }]
    }];
  }, null);
})();

/*
 * Public API Surface of angular-sdk
 */

/**
 * Generated bundle index. Do not edit.
 */

export { AccessKeyManagementComponent, AuditManagementComponent, DescopeAuthConfig, DescopeAuthModule, DescopeAuthService, DescopeComponent, RoleManagementComponent, SignInFlowComponent, SignUpFlowComponent, SignUpOrInFlowComponent, UserManagementComponent, UserProfileComponent, descopeAuthGuard, descopeInterceptor };
