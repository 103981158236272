import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { AudioService } from '../../services/audio.service';

@Component({
  selector: 'sbz-landing',
  templateUrl: './landing.component.html',
  styleUrl: './landing.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LandingComponent {
  isPlayingDemo: boolean = false;
  isPlayingPrompt: boolean = false;
  request: boolean = false;
  hasSong: boolean = false;
  prompt: string = '';
  currentlyPlayingSong: string = '';
  hadPrompt: boolean = false;
  limit: number = 120;
  @ViewChild('loaderBar') loaderBar?: ElementRef;
  constructor(
    private cdr: ChangeDetectorRef,
    private http: HttpClient,
    private modalService: ModalService,
    private audioService: AudioService
  ){
    this.hadPrompt = !!localStorage.getItem('audioUrl');
  }
  scrollToElement($element: Element): void {
    $element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }
  async produceSong() {
    try {
      this.request = true;
      const body = { prompt: this.prompt };
      const response = await fetch('https://hitcraft-utils.session42.xyz', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });
      const data: {sub_genres: string[], sub_genres_urls: string[]} = await response.json();
      if (!response.ok) {
        this.modalService.openErrorModal('There was an error loading the track. Please try again.');
        this.request = false;
        this.cdr.detectChanges()
        throw new Error(`HTTP error! Status: ${response.status}`);
      } else {
        let interval = setInterval(() => {
          if(this.loaderBar) {
            const width = parseInt(this.loaderBar.nativeElement.style.width);
            if(width !== 100) {
              const val = width ? width + 20 : 0 + 20;
              this.loaderBar.nativeElement.style.width = val + '%';
            } else {
              if(!localStorage.getItem('audioUrl')) {
                localStorage.setItem('audioUrl', data.sub_genres_urls[0])
              }
              this.audioService.createAudioFileFromSketch(data.sub_genres_urls[0]);
              clearInterval(interval);
              if(response.ok) {
                this.request = false;
                this.hadPrompt = true;
                this.cdr.detectChanges();
              }
            }
          }
        }, 1000)
      }
    } catch {
    }
  }
  playSketch() {
    this._playPause('demo', false);
    this.isPlayingDemo = !this.isPlayingDemo;
    this.isPlayingPrompt = false;
  }
  playPromptSong() {
    this.isPlayingDemo = false;
    this.isPlayingPrompt = !this.isPlayingPrompt;
    const storedAudio = localStorage.getItem('audioUrl');
    if(storedAudio) {
      this.audioService.createAudioFileFromSketch(storedAudio);
      this._playPause(storedAudio, true);
      this.currentlyPlayingSong = storedAudio ? storedAudio : '';
    }
  }
  changeLimit(value: string) {
    let limit = 120;
    if(this.limit >= 0) {
      limit -= value.length;
      this.limit = limit;
    }
  }
  navigateToUrl() {
    window.location.href = 'https://hitcraft-dev.session42.xyz/login';
  }
  _playPause(song: string, sketch: boolean) {
    if (this.currentlyPlayingSong !== song) {
      this.audioService.playSong(song, sketch);
      this.currentlyPlayingSong = song;
    } else {
      this.audioService.togglePlayPause();
    }
  }
}
