import {Injectable} from '@angular/core';
import {DescopeAuthService} from '@descope/angular-sdk';
import {jwtDecode} from 'jwt-decode';
import {BehaviorSubject, Observable} from 'rxjs';
import {User} from '../interfaces/user';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment.prod';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {LoadingService} from './loading.service';
import {ModalService} from './modal.service';

@Injectable({
  providedIn: 'root'
})
export class UserStateService {
  userNeedToRegister$ = new BehaviorSubject<boolean>(true);
  user$ = new BehaviorSubject<User | null>(null);
  sessionToken$ = new BehaviorSubject<string | null>(null);
  isWhiteList$ = new BehaviorSubject<boolean | null>(null);

  constructor(private authService: DescopeAuthService,
              private router: Router,
              private httpClient: HttpClient,
              private loadingService: LoadingService,
              private modalService: ModalService) {
    this.authService.session$.subscribe((session) => {
      if (session.sessionToken) {
        this.updateUserFromDescope(session.sessionToken);
      }
    });
  }

  updateUserFromDescope(sessionToken: string, pictureUrl: string | null = null) {
    this.sessionToken$.next(sessionToken);
    const decodedJwt: any = jwtDecode(sessionToken);
    this.user$.next({
      email: decodedJwt.email,
      phone: decodedJwt.phone,
      displayName: decodedJwt.displayName,
      youtubeChannel: decodedJwt.youtubeChannel,
      instagramMusicProfile: decodedJwt.instagramMusicProfile,
      spotifyProfileLink: decodedJwt.spotifyProfileLink,
      appleMusicProfile: decodedJwt.appleMusicProfile,
      pictureUrl: pictureUrl ? pictureUrl : decodedJwt.pictureUrl,
      finishedRegistration: decodedJwt.finishedRegistration,
      token: decodedJwt.sub,
      userHasSubscribed: decodedJwt.userHasSubscribed,
      selectedPackageId: decodedJwt.selectedPackageId,
      isWhiteList: decodedJwt.isWhiteList,
      queuePosition: decodedJwt.queuePosition,
      role: decodedJwt.role
    });
    const userNeedToRegister = !decodedJwt.email || !decodedJwt.phone || !decodedJwt.finishedRegistration;
    this.userNeedToRegister$.next(userNeedToRegister);
    this.isWhiteList$.next(decodedJwt.isWhiteList);
  
    if (!userNeedToRegister) {
      if (!decodedJwt.userHasSubscribed) {
        if(decodedJwt.isWhiteList) {
          console.log('routed');
          this.router.navigate(['/queue'])
        } else {
          this.router.navigate(['/subscribe']).then();
        }
      } else {
        this.router.navigate(['']).then();
      }
    }
  }

  updateUserFromRegister(user: User) {
    this.user$.next(user);
    this.userNeedToRegister$.next(false);
  }

  logoUt() {
    this.authService.descopeSdk.logout();
    this.user$.next(null);
  }

  checkUserInfoAndNavigate(): boolean {
    this.loadingService.toggleLoading(true);
    const userNeedToRegister = this.userNeedToRegister$.getValue();
    const user = this.user$.getValue();

    if (user?.userHasSubscribed) {
      this.loadingService.toggleLoading(false);
      return false;
    }

    this.loadingService.toggleLoading(false);
    if (userNeedToRegister) {
      this.router.navigate(['/login']).then();
      return true;
    }

    this.router.navigate(['/subscribe']).then();
    return true;
  }

  updateUserInfo(user: User) {
    this.loadingService.toggleLoading(true);
    const url = `${environment.baseUrl}/users/${user.token}`;

    const options = {
      headers: {'Authorization': `Bearer ${this.sessionToken$.getValue()}`}
    };

    this.httpClient.patch(url, user, options)
      .subscribe({
        next: () => {
          this.updateUserFromRegister(user);
          this.authService.refreshSession();
          this.router.navigate(['queue']).then();
        },
        error: (err: any) => {
          this.modalService.openErrorModal('We could not update user info. Please try again later.');
        },
        complete: () => {
          this.loadingService.toggleLoading(false);
        }
      });
  }
  getUsersWhiteList(): Observable<any> {
    return this.httpClient.get(`${environment.baseUrl}/users/whitelist`);
  }
  updateUserWhiteList(user: any, sendEmail: boolean, access: boolean): Observable<any> {
    this.loadingService.toggleLoading(true);
    const url = `${environment.baseUrl}/users/${user.userId}/whitelist`;

    const options = {
      headers: {'Authorization': `Bearer ${this.sessionToken$.getValue()}`}
    };

    return this.httpClient.patch(url, {customAttributes: user.customAttributes, sendEmail, access}, options)  
  }

  grandAccesses(range: {from: number, to: number}, sendEmail: boolean): Observable<any> {
    this.loadingService.toggleLoading(true);
    const url = `${environment.baseUrl}/users/grand-access`;

    const options = {
      headers: {'Authorization': `Bearer ${this.sessionToken$.getValue()}`}
    };

    return this.httpClient.post(url, {range, sendEmail}, options)
  }

  updateSubscriptionStatus(packageId: string) {
    const user = this.user$.getValue();
    if (user) {
      user.userHasSubscribed = true;
      user.selectedPackageId = packageId;
    }
  }

  // if (this.checkUserInfoAndNavigate()) {
  //   return;
  // }

  startFileUpload(): Promise<File> {
    return new Promise((resolve, reject) => {
      // Create a hidden file input element
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.accept = 'audio/*'; // Accept audio files only
      fileInput.style.display = 'none'; // Hide the input element

      // Append the file input to the body
      document.body.appendChild(fileInput);

      // Add an event listener to handle the file selection
      fileInput.addEventListener('change', (event: any) => {
        const file = event.target.files[0];
        if (file) {
          resolve(file);
        } else {
          reject(new Error('No file selected'));
        }
      });

      // Programmatically click the file input
      fileInput.click();

      // Remove the file input after selection
      fileInput.remove();
    });
  }

  uploadFile(file: File) {
    // Prepare form data
    const formData = new FormData();
    formData.append('file', file);
    formData.append('file_name', file.name);
    formData.append('user_id', this.user$.getValue()!.email);

    // Set the headers if needed
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.sessionToken$.getValue()}`,
      'enctype': 'multipart/form-data'
    });

    // Send the POST request
    return this.httpClient.post('https://sketch-upload.session42.xyz/sketch-upload', formData, {headers});
  }
}
