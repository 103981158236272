import {ChangeDetectionStrategy, Component} from '@angular/core';
import {BaseChatComponent} from '../base.chat.component';

@Component({
  selector: 'sbz-chat-prompt-input',
  templateUrl: './chat-prompt-input.component.html',
  styleUrl: './chat-prompt-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatPromptInputComponent extends BaseChatComponent {
  promptValue: string = '';

  send(): void {
    if (this.promptValue !== '') {
      // TODO - send prompt to server
      this.goToNext('song-name');
    }
  }
}
