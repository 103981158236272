<div [@fadeTransition] class="w-full flex justify-center">
  <div [ngClass]="{'lg:!w-[845px] sm:!w-[677px]': (user$ | async) && (userNeedToRegister$ | async)}"
       class="white-container sm:w-[596px] sm:max-w-none w-[90%] max-w-[596px] !rounded-[30.3px] md:!rounded-[42px]">
    @if ((user$ | async) && (userNeedToRegister$ | async)) {
      <sbz-register [activeStep]="activeStep" [submitForm]="submitForm"
                    (forceStepChange)="changeActiveStep($event)"></sbz-register>
    } @else {
      <div class="text-center relative">
        <p class="font-prop-normal lg:text-[38px] sm:text-[38px] text-[20px] font-light">Ready <strong
            class="font-bold">to Amplify
          Your</strong>
          Music?</p>
        <p class="font-prop-normal lg:text-[15px] lg-px-0 sm:text-[18px] text-[11px] px-2">Select an option below to
          <strong
              class="font-bold">log
            in</strong>
          or create a new account.</p>
      </div>
      <section [class.opacity-0]="hideDescope$ | async"
               class="transition-opacity duration-500 lg:ps-[68px] lg:pe-[49px] ps-[18px] m-[10px]">
        <descope (error)="onError($event)"
                 (ready)="onReady()"
                 (success)="onSuccess($event)"
                 [flowId]="flowId">
        </descope>
      </section>
    }
  </div>
</div>

@if ((user$ | async) && (userNeedToRegister$ | async)) {
  <div class="w-full flex justify-center lg:mt-[34px] sm:mt-[63px] mt-[28px]">
    <div class="lg:w-[845px] sm:w-[677px] sm:max-w-none max-w-[596px] w-[90%] flex justify-between sm:px-[60px]">
      <button class="main-button-design black-button lg:!w-[185px] sm:!w-[217px] !w-[101px]"
              [class.disabled]="activeStep === 1"
              (click)="changeActiveStep(activeStep - 1)">BACK
      </button>
      @if (activeStep === 3) {
        <button class="main-button-design gradient-button lg:!w-[185px] sm:!w-[217px] !w-[101px]" (click)="submit()">
          FINISH
        </button>
      } @else {
        <button class="main-button-design black-button lg:!w-[185px] sm:!w-[217px] !w-[101px]"
                (click)="changeActiveStep(activeStep + 1)">NEXT
        </button>
      }
    </div>
  </div>
}

