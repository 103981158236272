import {ChangeDetectionStrategy, Component} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {UserStateService} from '../../services/user-state.service';
import {slideMenu} from '../../animations/slide-menu-animation';
import {LoadingService} from '../../services/loading.service';
import {NavigationEnd, Router} from '@angular/router';
import {filter, tap} from 'rxjs';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'sbz-navbar',
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
  animations: [slideMenu],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavbarComponent {
  user$ = this.userStateService.user$;
  isLoading$ = this.loadingService.isLoading$;
  isAdmin: boolean = false;
  showQueue: boolean = false;

  isMenuOpen: boolean = false;

  constructor(private userStateService: UserStateService,
              private loadingService: LoadingService,
              private router: Router) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      window.scrollTo(0, 0);
    });
    this.userStateService.user$.subscribe(user => {
      this.isAdmin = user?.role === 'Admin';
      this.showQueue = user ? user.isWhiteList: false;
    })
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  logout() {
    this.userStateService.logoUt();
  }
}
