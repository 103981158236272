import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  currentlyActiveChatPart$ = new BehaviorSubject('welcome');
  createUserBubbleWIthText$ = new BehaviorSubject<{ event: string, text: string } | undefined>(undefined);
}
