<div [@fadeTransition] class="flex flex-col items-center">
  <div class="text-center xl:mt-[53px] lg:mt-[66px] mt-[20px]">
    <p class="main-title">Welcome to <strong class="font-bold">Hitcraft</strong></p>
    <p class="sub-title">
      We're here to put the power back in the hands of artists, with powerful tools<br> that can change the
      trajectory of your music career. Control your music, your way!</p>
    <button (click)="checkLogin()" class="main-button-design gradient-button 3xl:mt-[64px] lg:mt-[51px] mt-[31px]">
      Start Using Hitcraft
    </button>
  </div>

  <div class="vision-container">
    <div class="eye-image">
      <img alt="Image distribute 1" fill ngSrc="/assets/images/about-us/eye.webp" priority>
    </div>
    <p class="main-title !text-white 3xl:mt-[66px] lg:mt-[53px] mt-[19px]">
      Our <strong class="font-bold">Mission</strong>
    </p>
    <p class="about-us-text">
      Our mission is to reshape the music landscape, liberating artists from restrictive contracts through
      AI-driven services. Hitcraft, our groundbreaking music distribution platform, is the most advanced
      Distribution platform today....
    </p>

    <button class="read-more-text" routerLink="/articles/read-more">
      Read More
    </button>
  </div>

  <div class="team-container">
    <div class="team-image">
      <img alt="Image distribute 1" fill ngSrc="/assets/images/about-us/team.webp" priority>
    </div>

    <p class="main-title 3xl:mt-[74px] lg:mt-[58px] mt-[8px]">
      The <strong class="font-bold">Team</strong>
    </p>

    <div class="flex flex-col items-center 3xl:mt-[24px] lg:mt-[12px] mt-[8px]">
      <div class="team-members-container">
        @for (member of teamMembers.slice(currentSet * 5, (currentSet + 1) * 5); track member.id; let i = $index) {
          <div class="team-member-image" (click)="toggleInfo(i)" [class.active]="activeIndex === i">
            <img [@fadeTransition] [ngSrc]="member.image" alt="{{ member.name }}" fill>
            <div class="member-info">
              <span class="member-name">{{ member.name }}</span>
              <span class="member-role">{{ member.role }}</span>
            </div>
          </div>
        }
      </div>

      <!-- Navigation -->
      <div class="carousel-navigation">
        @for (item of [0, 1]; track item) {
          <button
              class="nav-button"
              [class.selected]="item === currentSet"
              (click)="changeSet(item)">
          </button>
        }
      </div>
    </div>

  </div>

  <p class="main-title bottom-title">Experience <strong class="font-bold">the Future of Music</strong>
    Distribution!
  </p>
  <button (click)="checkLogin()" class="bottom-button main-button-design gradient-button">
    Start Using Hitcraft
  </button>
  <sbz-footer></sbz-footer>
</div>
