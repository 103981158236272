import {ChangeDetectionStrategy, Component} from '@angular/core';
import {BaseChatComponent} from '../base.chat.component';

@Component({
  selector: 'sbz-chat-song-name',
  templateUrl: './chat-song-name.component.html',
  styleUrl: './chat-song-name.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatSongNameComponent extends BaseChatComponent {
  songName: string = '';

  send(): void {
    if (this.songName !== '') {
      // TODO - send song name to server
      this.goToNext('lyrics-input');
    }
  }
}
