<p class="chat-text">
  First up, you'll upload a recorded sketch of singing and a supporting
  instrument, tracked with a metronome. (Don't worry, we're working on
  lifting that limitation soon!)
</p>

<div class="chat-blue-container">
  <button (click)="startFileUpload()"
          [class.chat-button-generic-disabled]="!currentActiveComponent"
          [disabled]="!currentActiveComponent"
          [ngClass]="{'file-uploaded': fileName$ | async}"
          class="gradient-button">
    upload sketch
  </button>

  @if (fileName$ | async) {
    <p>{{ fileName$ | async }}</p>
  }
</div>
