import {ChangeDetectionStrategy, Component} from '@angular/core';
import {BaseChatComponent} from '../base.chat.component';

@Component({
  selector: 'sbz-chat-lyrics-input',
  templateUrl: './chat-lyrics-input.component.html',
  styleUrl: './chat-lyrics-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatLyricsInputComponent extends BaseChatComponent {
  lyrics: string = '';
  maxRows: number = 8; // Set your desired max rows here

  adjustHeight(event: Event): void {
    const textArea = event.target as HTMLTextAreaElement;
    textArea.style.height = 'auto';

    const lineHeight = parseInt(window.getComputedStyle(textArea).lineHeight || '24', 10); // Default line-height or extract from CSS
    const maxHeight = lineHeight * this.maxRows;

    if (textArea.scrollHeight > maxHeight) {
      textArea.style.height = `${maxHeight}px`;
      textArea.style.overflowY = 'auto';
    } else {
      textArea.style.height = `${textArea.scrollHeight}px`;
      textArea.style.overflowY = 'hidden';
    }
  }

  submitLyrics(): void {
    if (this.lyrics !== '') {
      this.goToNext('after-lyrics');
    }
  }
}
