import {AfterViewInit, Directive, Injector, OnInit, Type} from '@angular/core';
import {ChatService} from '../../../services/chat.service';
import {UserStateService} from '../../../services/user-state.service';
import {AudioService} from '../../../services/audio.service';
import {ModalService} from '../../../services/modal.service';

@Directive()
export class BaseChatComponent implements AfterViewInit{
  currentActiveComponent = true;

  constructor(public chatService: ChatService,
              public audioService: AudioService,
              public userStateService: UserStateService,
              public modalService: ModalService) {
  }

  ngAfterViewInit() {
    this.afterViewInit();
  }

  protected afterViewInit(): void {
    // Derived components can implement their own logic here
  }

  goToNext(event: string) {
    this.switchActiveComponent(event);
  }

  createChatBubbleWIthText(event: string, text: string) {
    this.currentActiveComponent = false;
    this.chatService.createUserBubbleWIthText$.next({event, text});
  }

  goToNextComponent(event: string, componentName: string) {
    this.switchActiveComponent(event);
    setTimeout(() => {
      this.goToNext(componentName);
    }, 1600);
  }

  private switchActiveComponent(event: string) {
    this.currentActiveComponent = false;
    this.chatService.currentlyActiveChatPart$.next(event);
  }
}
