<p class="chat-text">
  WOW!, Amazing idea for the style of song. Now we need a a good name for it.
</p>

<div class="chat-white-container">
  <input [(ngModel)]="songName" [readOnly]="!currentActiveComponent"
         [readonly]="!currentActiveComponent"
         placeholder="I bet it's a good name :)"
         type="text">
  <button (click)="send()"
          [class.chat-button-generic-disabled]="!currentActiveComponent"
          class="gradient-purple-button sm:!w-1/5">
    Send
  </button>
</div>
