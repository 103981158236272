<div class="landing pt-[42vw] lg:pt-[350px]">
    <section class="top flex flex-col items-center px-[10vw] sm:mb-32">
        <p class="title text-5xl hidden-block text-center font-light">AI-Empowered Music Production <span class="font-bold">for Creators</span></p>
        <p class="title sm:hidden text-5xl text-center font-light">Let’s get <span class="font-bold">Your Song</span> out there!</p>
        <p class="subtitle font-medium hidden-block text-[28.5px] text-center mt-4 xsm:mb-10 xs:mb-20 sm:mb-16 3xl:mb-20">Unleash your creativity with HitCraft, the virtual producer that’s revolutionizing music production</p>
        <p class="subtitle sm:hidden font-medium text-[28.5px] text-center mt-4 xsm:mt-2 xsm:mb-10 xs:mb-20 sm:mb-16 3xl:mb-20">Start your production Jurney with us!</p>
        <button (click)="navigateToUrl()" class="main-button-design gradient-purple-button 2xl:w-[290px] xsm:mb-12 xs:mb-28 sm:mb-10 3xl:mb-12">Sign up for the wishlist</button>
        <div (click)="scrollToElement(demo)" class="font-medium xsm:text-sm xs:text-sm sm:text-sm 3xl:text-lg cursor-pointer pb-[1px] border-b-[1px] border-black">Try the demo</div>
    </section>
    <section class="instant grid grid-cols-2 sm-m:grid-cols-1 sm:pl-[104px] lg:pl-[114px] xl:pl-[202px] 2xl:pl-[253px] lg:mb-36">
        <div class="left-block flex flex-col justify-center sm-m:px-[10vw] sm:order-1 sm-m:order-2 sm-m:items-center sm-m:text-center">
            <p class="title sm:text-4xl 3xl:text-5xl font-light xsm:mb-5 xs:mb-14 sm:mb-10 3xl:mb-12">Effortless <br>
                <span class="font-bold">Music Production</span></p>
            <p class="subtitle text-lg sm:text-lg 3xl:text-2xl font-medium pr-0 lg:pr-32 xsm:mb-9 xs:mb-20 sm:mb-11 3xl:mb-14">Whether you’re a pro exploring new ideas or a budding musician ready to share your songs, HitCraft transforms your sketches into polished, professional tracks.</p>
            <button (click)="navigateToUrl()" class="main-button-design gradient-purple-button 2xl:w-[290px]">Sign up for the wishlist</button>
        </div>
        <div class="right-block sm-m:text-right sm:order-2 sm-m:order-1">
            <img src="assets/images/landing/layer-3433@3x.png" alt="">
        </div>
    </section>
    <section class="effortless grid grid-cols-2 sm-m:grid-cols-1 sm:pr-[104px] lg:pr-[114px] xl:pr-[202px] 2xl:pr-[253px]">
        <div class="left-block sm-m:text-right sm:order-1 sm-m:order-1">
            <img src="assets/images/landing/layer-3429@3x.png" alt="">
        </div>
        <div class="right-block flex flex-col justify-center sm:pl-40 sm-m:px-[10vw] sm:order-2 sm-m:order-2 sm-m:items-center sm-m:text-center sm:-mt-32">
            <p class="title sm:text-4xl 3xl:text-5xl font-light xsm:mb-5 xs:mb-14 sm:mb-10 3xl:mb-12">Customize and <br>
                <span class="font-bold">Share</span></p>
            <p class="subtitle text-lg sm:text-lg 3xl:text-2xl font-medium xsm:mb-9 xs:mb-20 sm:mb-11 3xl:mb-14">With HitCraft, you can download entire projects to personalize them further or instantly share your favorite tracks to streaming platforms.</p>
            <button (click)="scrollToElement(demo)" class="main-button-design gradient-purple-button 2xl:w-[290px]">Click here to try now</button>
        </div>
    </section>
    <section class="inspiration grid grid-cols-2 sm-m:grid-cols-1 sm:pl-[104px] lg:pl-[114px] xl:pl-[202px] 2xl:pl-[253px] xsm:mb-20 xs:mb-44 sm:mb-10 lg:mb-16 3xl:mb-20">
        <div class="left-block flex flex-col sm-m:px-[10vw] justify-center sm:order-1 sm-m:order-2 sm-m:items-center sm-m:text-center">
            <p class="title sm:text-4xl 3xl:text-5xl font-light xsm:mb-5 xs:mb-14 sm:mb-10 3xl:mb-12">A World <br>
                <span class="font-bold">Of Inspiration</span></p>
            <p class="subtitle text-lg sm:text-lg 3xl:text-2xl font-medium pr-0 lg:pr-24 xsm:mb-9 xs:mb-20 sm:mb-11 3xl:mb-14">Try out your songs with various unique production styles and innovative arrangements to elevate your music with HitCraft.</p>
            <button (click)="navigateToUrl()" class="main-button-design gradient-purple-button 2xl:w-[290px]">Sign up for the wishlist</button>
        </div>
        <div class="right-block sm-m:text-right sm:order-2 sm-m:order-1">
            <img src="assets/images/landing/layer-538@3x.png" alt="">
        </div>
    </section>
    <section #demo class="how-it-works px-[10vw]">
        <p class="title sm:text-4xl 3xl:text-5xl text-center font-light xsm:mb-8 xs:mb-16 3xl:mb-24">See how <span class="font-bold">HitCraft</span> works</p>
        <div class="demo mt-8">
            <div class="produce-song text-white flex flex-col justify-start w-2/5 py-8 lg:py-16 px-4 lg:pl-16 lg:px-0">
                <p class="text-[6vw] mb-7 lg:mb-[42px] text-center lg:text-left lg:text-4xl font-light">Let's produce a song!</p>
                <div class="action">
                <p class="mb-5 lg:text-lg font-medium">1. Upload a sketch</p>
                <div class="listen-sketch mb-8">
                    <div class="sketch-container flex justify-start items-center">
                        <div (click)="playSketch()" class="play flex justify-center items-center ml-[7px] sm:ml-[10px] cursor-pointer">
                            <fa-icon class="text-white" [icon]="isPlayingDemo ? 'pause' : 'play'"></fa-icon>
                        </div>
                        <span class="ml-2 lg:ml-6 lg:text-lg">Listen to our demo sketch</span>
                    </div>
                </div>
                @if (!hadPrompt) {
                    <p class="mb-5 lg:text-lg font-medium">2. Choose the style you want</p>
                    <textarea [(ngModel)]="prompt" (ngModelChange)="changeLimit($event)" [maxlength]="120" class="xs:text-[9px] sm:text-[19px] lg:text-base leading-[9px] lg:leading-4 p-2 sm:p-5" rows="5" name="prompt" id="prompt" placeholder="Example: 90’s grunge style rock with acoustic guitars"></textarea>
                    <p class="limit text-right opacity-50 mt-2 lg:mt-5 lg:text-sm mb-8 lg:mb-11">{{limit}} characters left</p>
                }
                </div>
                @if(!hadPrompt && !request) {
                    <button (click)="produceSong()" [disabled]="!prompt" [ngClass]="{'disabled': !prompt}" class="main-button-design black-button">Produce the song!</button>
                }
                @if(request) {
                    <button class="main-button-design black-button loader flex justify-center items-center cursor-wait">
                        <div class="box-shadow absolute left-0 top-0"></div>
                        <div #loaderBar class="progress absolute left-0 top-0"></div>
                        <div id="circularG">
                            <div id="circularG_1" class="circularG">
                            </div>
                            <div id="circularG_2" class="circularG">
                            </div>
                            <div id="circularG_3" class="circularG">
                            </div>
                            <div id="circularG_4" class="circularG">
                            </div>
                            <div id="circularG_5" class="circularG">
                            </div>
                            <div id="circularG_6" class="circularG">
                            </div>
                            <div id="circularG_7" class="circularG">
                            </div>
                            <div id="circularG_8" class="circularG">
                            </div>
                        </div>
                    </button>
                }
               @if (hadPrompt) {
                    <button (click)="playPromptSong()" class="main-button-design black-button"><fa-icon class="text-white mr-5" [icon]="isPlayingPrompt ? 'pause' : 'play'"></fa-icon>Listen the final track!</button>
               }
            </div>
        </div>
    </section>
    <section class="px-[10vw] xsm:mt-20 xs:mt-36 sm:mt-44 3xl:mt-56">
        <p class="title sm:text-4xl 3xl:text-5xl text-center font-light xsm:mb-9 xs:mb-20 sm:mb-16 lg:mb-20 3xl:mb-24">What our <span class="font-bold">Users Say</span></p>
        <div class="users-feedbacks grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-12">
            <div class="feedback bg-white rounded-3xl xsm:p-6 xs:p-8 lg:p-6 3xl:p-8 text-center">
                <div class="bg-gray-50 rounded-3xl p-4 lg:p-5">
                    <div class="rating mb-8">
                        <fa-icon class="text-yellow-300 cursor-pointer mx-1" icon="star"></fa-icon>
                        <fa-icon class="text-yellow-300 cursor-pointer mx-1" icon="star"></fa-icon>
                        <fa-icon class="text-yellow-300 cursor-pointer mx-1" icon="star"></fa-icon>
                        <fa-icon class="text-yellow-300 cursor-pointer mx-1" icon="star"></fa-icon>
                        <fa-icon class="text-yellow-300 cursor-pointer mx-1" icon="star"></fa-icon>
                    </div>
                    <div class="response font-medium text-[27px] lg:text-sm xl:text-lg">
                        "HitCraft is a game-changer! As a producer, the efficiency and precision it offers in transforming basic sketches into polished tracks is unparalleled. It’s like having a top-tier production team at your fingertips."
                    </div>
                </div>
                <div class="user sm-m:mt-8 sm:mt-16 lg:mt-9">
                    <p class="font-bold xsm:text-xl xs:text-4xl lg:text-2xl xl:text-3xl 2xl:text-3xl 3xl:text-4xl">Samuel Pesci</p>
                    <p class="font-light xsm:text-sm xs:text-2xl lg:text-lg 2xl:text-xl">Music Producer</p>
                </div>
            </div>
            <div class="feedback bg-white rounded-3xl xsm:p-6 xs:p-8 lg:p-6 3xl:p-8 text-center">
                <div class="bg-gray-50 rounded-3xl p-4 lg:p-5">
                    <div class="rating mb-8 gap-1">
                        <fa-icon class="text-yellow-300 cursor-pointer mx-1" icon="star"></fa-icon>
                        <fa-icon class="text-yellow-300 cursor-pointer mx-1" icon="star"></fa-icon>
                        <fa-icon class="text-yellow-300 cursor-pointer mx-1" icon="star"></fa-icon>
                        <fa-icon class="text-yellow-300 cursor-pointer mx-1" icon="star"></fa-icon>
                        <fa-icon class="text-yellow-300 cursor-pointer mx-1" icon="star"></fa-icon>
                    </div>
                    <div class="response font-medium text-[27px] lg:text-sm xl:text-lg">
                        "As a musician who’s trying to build his way up in the business I can testify that HitCraft has been a real lifesaver for me. It’s so easy to use and helps me turn my ideas into real songs try and again. Honestly, I already feel like a pro…"
                    </div>
                </div>
                <div class="user mt-12">
                    <p class="font-bold xsm:text-xl xs:text-4xl lg:text-2xl xl:text-3xl 2xl:text-3xl 3xl:text-4xl">Jess Penny</p>
                    <p class="font-light xsm:text-sm xs:text-2xl lg:text-lg 2xl:text-xl">Singer-songwriter</p>
                </div>
            </div>
            <div class="feedback bg-white rounded-3xl xsm:p-6 xs:p-8 lg:p-6 3xl:p-8 text-center">
                <div class="bg-gray-50 rounded-3xl p-4 lg:p-5">
                    <div class="rating mb-8">
                        <fa-icon class="text-yellow-300 cursor-pointer mx-1" icon="star"></fa-icon>
                        <fa-icon class="text-yellow-300 cursor-pointer mx-1" icon="star"></fa-icon>
                        <fa-icon class="text-yellow-300 cursor-pointer mx-1" icon="star"></fa-icon>
                        <fa-icon class="text-yellow-300 cursor-pointer mx-1" icon="star"></fa-icon>
                        <fa-icon class="text-yellow-300 cursor-pointer mx-1" icon="star"></fa-icon>
                    </div>
                    <div class="response font-medium text-[27px] lg:text-sm xl:text-lg">
                        "I’ve seen a few things in the music industry, but this thing still managed to impress me. As opposed to all those complex softwares, HitCraft is intuitive, smart and makes the daunting production process so slik.”
                    </div>
                </div>
                <div class="user mt-12">
                    <p class="font-bold xsm:text-xl xs:text-4xl lg:text-2xl xl:text-3xl 2xl:text-3xl 3xl:text-4xl">Christopher Peter</p>
                    <p class="font-light xsm:text-sm xs:text-2xl lg:text-lg 2xl:text-xl">Musician and Sound Engeneer</p>
                </div>
            </div>
        </div>
        <div class="text-center my-28">
            <button (click)="navigateToUrl()" class="main-button-design gradient-purple-button 2xl:w-[290px]">Sign up for the wishlist</button>
        </div>
    </section>
</div>
<sbz-footer></sbz-footer>  