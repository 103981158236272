import {Component} from '@angular/core';
import {BaseChatComponent} from '../base.chat.component';

@Component({
  selector: 'sbz-chat-song-loading',
  templateUrl: './chat-song-loading.component.html',
  styleUrl: './chat-song-loading.component.scss'
})
export class ChatSongLoadingComponent extends BaseChatComponent {
  protected override afterViewInit() {
    setTimeout(() => {
      this.goToNext('player');
    }, 2500);
  }
}


