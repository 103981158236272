import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  public isPromptModalVisible$ = new BehaviorSubject<boolean>(false);
  public isErrorModalVisible$ = new BehaviorSubject<boolean>(false);
  public isCouponModalVisible$ = new BehaviorSubject<boolean>(false);
  public promptMessage: string = '';
  public errorMessage: string = '';

  constructor() {
  }

  openPromptModal(message: string): void {
    this.promptMessage = message;
    this.isPromptModalVisible$.next(true);
  }

  closePromptModal(): void {
    this.isPromptModalVisible$.next(false);
  }

  openErrorModal(message: string): void {
    this.errorMessage = message;
    this.isErrorModalVisible$.next(true);
  }

  closeErrorModal(): void {
    this.isErrorModalVisible$.next(false);
  }

  openCouponModal(): void {
    this.isCouponModalVisible$.next(true);
  }

  closeCouponModal(): void {
    this.isCouponModalVisible$.next(false);
  }
}
