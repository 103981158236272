import {ChangeDetectionStrategy, Component} from '@angular/core';
import {BaseChatComponent} from '../base.chat.component';
import {ChatService} from '../../../../services/chat.service';
import {AudioService} from '../../../../services/audio.service';
import {UserStateService} from '../../../../services/user-state.service';
import {ModalService} from '../../../../services/modal.service';
import {Router} from '@angular/router';

@Component({
  selector: 'sbz-chat-jump',
  templateUrl: './chat-jump.component.html',
  styleUrl: './chat-jump.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatJumpComponent extends BaseChatComponent {
  constructor(chatService: ChatService,
              audioService: AudioService,
              userStateService: UserStateService,
              modalService: ModalService,
              private router: Router) {
    super(chatService, audioService, userStateService, modalService);
  }

  goToLink(link: string) {
    this.router.navigate([]).then((result) => {
      window.open(`${window.location.origin}/${link}`, '_blank');
    });
  }
}
