<div class="player-wrapper">
  <div #playerBlocks class="player-blocks">
    <div *ngIf="isLoading" class="loading-indicator">
      <div class="spinner"></div>
      Loading audio...
    </div>
    <div (click)="toggleDropdown(block, i, $event)"
         *ngFor="let block of blocks; let i = index"
         [style.alignItems]="'center'"
         [style.color]="'#252931'"
         [style.display]="'flex'"
         [style.height]="'100%'"
         [style.justifyContent]="'center'"
         [style.left]="block.left"
         [style.position]="'absolute'"
         [style.width]="block.width"
         class="block">
      {{ block.text }}

      <!-- Bottom bar within each block -->
      <div [style.backgroundColor]="block.color"
           [style.borderRadius]="'3px'"
           [style.bottom]="'0'"
           [style.height]="'3px'"
           [style.position]="'absolute'"
           [style.width]="'100%'"
           class="bottom-bar">
      </div>
    </div>
  </div>
  <div class="waveform-wrapper">
    <div #waveform id="waveform"></div>
    <div #timestampDisplay [class.hidden]="!hoverTimestamp" class="timestamp-display">
      {{ hoverTimestamp }}
    </div>
    <div #progressDiv class="progress-div"></div>
    <button (click)="playPause()">
      <fa-icon [icon]="isPlaying ? 'pause' : 'play'"></fa-icon>
    </button>
  </div>

  <div *ngIf="isDropdownVisible"
       class="absolute dropdown-menu z-10 mt-1 w-44 text-base list-none bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700">
    <ul aria-labelledby="dropdownButton" class="py-1">
      <li *ngFor="let option of ['Intro Verse Pre', 'Chorus Chorus Post', 'Chorus Interlude C', 'Part Coda Outro']; let optionIndex = index">
        <a (click)="updateBlockName(option, activeDropdownIndex || 0, $event)"
           class="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-600"
           href="#">
          {{ option }}
        </a>
      </li>
    </ul>
  </div>

  <div *ngIf="isContextMenuVisible"
       [style.left.px]="contextMenuPosition.x"
       [style.top.px]="contextMenuPosition.y"
       class="absolute context-menu z-10 mt-1 w-44 text-base list-none bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700">
    <ul aria-labelledby="dropdownButton" class="py-1">
      <li (click)="performAction('split')">
        <a class="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-600"
           href="#">
          Split
        </a>
      </li>
      <li (click)="performAction('moveLeft')">
        <a class="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-600"
           href="#">
          Move left
        </a>
      </li>
      <li (click)="performAction('moveRight')">
        <a class="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-600"
           href="#">
          Move right
        </a>
      </li>
      <li (click)="performAction('delete')">
        <a class="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-600"
           href="#">
          Delete
        </a>
      </li>
    </ul>
  </div>
</div>
