<div #queueBlock [@fadeTransition] class="w-full flex justify-center px-7">
    <div class="queue-container text-center pt-24 rounded-3xl shadow-lg shadow-zinc-500 bg-white relative">
        <!-- <sbz-robot></sbz-robot> -->
        <div class="title text-4xl font-bold mb-6 px-7">
            You’re on the Waitlist!
        </div>
        <div class="subtitle mb-2 text-3xl text-center font-light px-7">
            Your current position is <span class="font-bold italic">{{userQueueNumber}}</span>
            @if (totalQueueNumber) {
                out of <span class="font-bold italic">{{totalQueueNumber}}</span> Musicians.
            }
        </div>
        <div class="share">
            <div class="networks flex items-center justify-center relative top-10">
                <a class="mx-4" href="https://www.facebook.com/sharer/sharer.php?u=#https://8be5-159-224-249-208.ngrok-free.app/" target="_blank">
                    <img width="80px" src="../../../assets/images/queue/fb.webp" alt="facebook">
                </a>
                <!-- <a href="https://www.facebook.com/dialog/feed?&app_id=2341234234&href=https://8be5-159-224-249-208.ngrok-free.app/&display=popup&quote=hitcraft&hashtag=#HITRAFT&redirect_uri=https://8be5-159-224-249-208.ngrok-free.app/" target="_blank">
                    <fa-icon class="text-4xl mx-2" [icon]="['fab', 'facebook']"></fa-icon>
                </a> -->
                <a class="mx-4" href="https://twitter.com/intent/tweet?url=https://8be5-159-224-249-208.ngrok-free.app/">
                    <img width="80px" src="../../../assets/images/queue/x.webp" alt="x">
                </a>
                <a class="mx-4 cursor-pointer" (click)="downloadImage()">
                    <img width="80px" src="../../../assets/images/queue/inst.webp" alt="instagram">
                </a>
                <a class="mx-4 cursor-pointer" (click)="downloadImage()">
                    <img width="80px" src="../../../assets/images/queue/tik.webp" alt="tiktok">
                </a>
            </div>
            <div class="copy-link rounded-3xl pt-24 pb-16" (click)="copyToClipBoard(inputValue)">
                <div class="copy-input relative">
                    <input #inputValue class="rounded-[29px] cursor-pointer focus:outline-none focus:border-none py-4 px-7" readonly type="text" [value]="url">
                    <img class="cursor-pointer relative right-10" width="22px" src="../../../assets/images/queue/copy.png" alt="">
                </div>
            </div>
        </div>
    </div>
</div>

<div id="download" class="hidden">
    <img #canvas>
    <a #downloadLink></a>
</div>