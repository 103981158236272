<div *ngIf="modalService.isPromptModalVisible$ | async" [@fadeTransition] class="modal-background">
  <div class="modal-container error-modal">
    <h2 class="modal-header">User <strong class="font-bold">Prompt</strong></h2>
    <p [innerHTML]="modalService.promptMessage | safeHtml" class="modal-content"></p>
    <button (click)="modalService.closePromptModal()"
            class="main-button-design gradient-button !w-1/2 mt-[37px] mx-auto !text-[18px] !h-[61px]">
      OK
    </button>
  </div>
</div>
