import {Component, ElementRef, HostListener, Renderer2, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import WaveSurfer from 'wavesurfer.js';
import {BehaviorSubject} from 'rxjs';
import {BaseChatComponent} from '../base.chat.component';
import {ModalService} from '../../../../services/modal.service';
import {ChatService} from '../../../../services/chat.service';
import {AudioService} from '../../../../services/audio.service';
import {UserStateService} from '../../../../services/user-state.service';

@Component({
  selector: 'sbz-chat-chat-player',
  templateUrl: './chat-player.component.html',
  styleUrls: ['./chat-player.component.scss']
})
export class ChatPlayerComponent extends BaseChatComponent {
  @ViewChild('waveform') waveformEl!: ElementRef;
  @ViewChild('playerBlocks') playerBlocksEl!: ElementRef<HTMLDivElement>;
  @ViewChild('progressDiv') progressDiv!: ElementRef;
  waveSurfer!: WaveSurfer;
  @ViewChild('timestampDisplay') timestampDisplay!: ElementRef<HTMLDivElement>;
  hoverTimestamp?: string;
  struct!: any[];
  time_signature = '';
  bpm = 0;
  blocks: any[] = [];
  isPlaying = false;
  isDropdownVisible = false;
  currentBlock: any = null;
  activeDropdownIndex: number | null = null;
  isLoading = true;
  isContextMenuVisible = false;
  contextMenuPosition = {x: 0, y: 0};
  activeBlockIndex: number | null = null;
  clickPercent = 0;
  private blocksSubject = new BehaviorSubject<any[]>([]);
  blocks$ = this.blocksSubject.asObservable();

  constructor(private httpClient: HttpClient,
              private elementRef: ElementRef,
              private renderer: Renderer2,
              modalService: ModalService,
              chatService: ChatService,
              audioService: AudioService,
              userStateService: UserStateService) {
    super(chatService, audioService, userStateService, modalService);
  }

  onClickOutside(event: Event): void {
    console.log('onClickOutside');
    console.log('isDropdownVisible: ', this.isDropdownVisible);
    const dropdownElement = this.elementRef.nativeElement.querySelector('.dropdown-menu');
    console.log('onClickOutside dropdownElement', dropdownElement);
    if (dropdownElement && !dropdownElement.contains(event.target)) {
      this.isDropdownVisible = false;
      this.activeDropdownIndex = null;
    }
  }

  initWaveSurfer(url: string) {
    this.waveSurfer = WaveSurfer.create({
      container: this.waveformEl.nativeElement,
      waveColor: '#393d44',
      progressColor: '#393d44',
      cursorColor: '#e53252',
      cursorWidth: 3,
      height: 60
    });

    this.waveSurfer.load(url);
    this.waveSurfer.on('ready', () => {
      this.createBlocks();
      this.isLoading = false;
    });

    // this.waveSurfer.load('path_to_your_audio_file.mp3');
    this.waveSurfer.on('audioprocess', () => {
      this.updateProgress();
    });
    this.waveSurfer.on('seeking', () => {
      this.updateProgress();
    });

    this.waveformEl.nativeElement.addEventListener('mousemove', this.showTimestamp.bind(this));
    this.waveformEl.nativeElement.addEventListener('mouseleave', () => this.hoverTimestamp = undefined);

    this.waveformEl.nativeElement.addEventListener('contextmenu', this.openContextMenu.bind(this));
  }

  calculateTotalBars(duration: number, bpm: number, timeSignature: string): number {
    const [beatsPerBar] = timeSignature.split('/').map(Number);
    const beatDuration = 60 / bpm;
    const barDuration = beatDuration * beatsPerBar;
    return Math.floor(duration / barDuration);
  }

  createBlocks() {
    const totalBars = this.calculateTotalBars(this.waveSurfer.getDuration(), this.bpm, this.time_signature);

    const colors = [
      '#9546ef',
      '#f93251',
      '#fd8730',
      '#41d4aa'
    ];

    console.log('this.struct: ', this.struct);
    this.struct.forEach((block, index) => {
      const blockElement = document.createElement('div');
      const startPercent = (block[1] / totalBars) * 100;
      const endPercent = (block[2] / totalBars) * 100;
      const blockWidthPercent = endPercent - startPercent;

      const newBlock = {
        text: block[0],
        left: `${startPercent}%`,
        width: `${blockWidthPercent}%`,
        color: colors[index % colors.length],
        index: index  // keep track of index for operations
      };
      this.blocks.push(newBlock);
    });
    this.blocksSubject.next(this.blocks);
  }

  showTimestamp(event: MouseEvent): void {
    const boundingRect = this.waveformEl.nativeElement.getBoundingClientRect();
    const x = event.clientX - boundingRect.left;
    const duration = this.waveSurfer.getDuration();
    const time = (x / boundingRect.width) * duration;
    this.hoverTimestamp = this.formatTime(time);
    this.timestampDisplay.nativeElement.style.left = `${event.clientX - boundingRect.left}px`;  // Position the timestamp
    this.timestampDisplay.nativeElement.style.display = 'block';
  }

  formatTime(seconds: number): string {
    const pad = (num: number) => num < 10 ? '0' + num : num;
    return `${pad(Math.floor(seconds / 60))}:${pad(Math.floor(seconds % 60))}`;
  }

  updateProgress(): void {
    const progress = (this.waveSurfer.getCurrentTime() / this.waveSurfer.getDuration()) * 100;
    this.progressDiv.nativeElement.style.width = `${progress}%`;
  }

  playPause() {
    this.isPlaying = !this.isPlaying;
    this.waveSurfer.playPause();
  }

  toggleDropdown(block: any, index: number, event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    if (this.activeDropdownIndex === index) {
      this.activeDropdownIndex = null; // Close this dropdown
    } else {
      this.activeDropdownIndex = index; // Open this dropdown and close others
    }

    if (this.activeDropdownIndex !== null) {
      console.log('this.activeDropdownIndex: ', this.activeDropdownIndex);
      this.isDropdownVisible = true;
      setTimeout(() => {
        this.positionDropdown(event);
      }, 0);
    }
  }

  positionDropdown(event: MouseEvent): void {
    const dropdownElement = this.elementRef.nativeElement.querySelector('.dropdown-menu');
    console.log('dropdownElement: ', dropdownElement);
    if (dropdownElement) {
      dropdownElement.style.position = 'absolute';
      dropdownElement.style.left = `${event.clientX}px`;
      dropdownElement.style.top = `${event.clientY}px`;
    }
  }

  updateBlockName(newName: string, index: number, event: MouseEvent): void {
    event.preventDefault();
    console.log('this.struct: ', this.struct);
    if (index !== null && index < this.struct.length) {
      this.struct[index][0] = newName;
      this.blocks[index] = {...this.blocks[index], text: newName};
      this.activeDropdownIndex = null;
      this.isDropdownVisible = false;
    }

    this.saveData();
  }

  saveData() {
    return;
    const endpoint = 'https://sketch-upload.session42.xyz/set-struct';
    const formData: FormData = new FormData();
    formData.append('sketch_id', '6616584c5a195c0284ab2ece');
    formData.append('structure', JSON.stringify(this.struct));

    this.httpClient.post(endpoint, formData).subscribe({
      next: (response) => {
        console.log('Structure saved successfully', response);
      },
      error: (error) => {
        console.error('Error saving structure', error);
      }
    });
  }

  getBlockIndexAtPosition(event: MouseEvent): number | null {
    const waveformRect = this.waveformEl.nativeElement.getBoundingClientRect();
    const clickX = event.clientX - waveformRect.left; // x position within the element.
    const clickPercent = (clickX / waveformRect.width) * 100;

    this.clickPercent = clickPercent;

    console.log('clickPercent: ', clickPercent);
    console.log('this.blocks: ', this.blocks);

    for (let i = 0; i < this.blocks.length; i++) {
      const block = this.blocks[i];
      const start = parseFloat(block.left);
      const end = start + parseFloat(block.width);
      if (clickPercent >= start && clickPercent <= end) {
        return i;
      }
    }
    return null;
  }

  splitBlock(index: number | null): void {
    if (index === null) {
      return;
    }

    const block = this.blocks[index];
    const startPercent = parseFloat(block.left);
    const endPercent = startPercent + parseFloat(block.width);
    const splitPoint = (startPercent + endPercent) / 2;

    block.width = `${splitPoint - startPercent}%`;
    const newBlock = {
      text: block.text,
      left: `${splitPoint}%`,
      width: `${endPercent - splitPoint}%`,
      color: block.color,
      index: this.blocks.length
    };
    this.blocks.push(newBlock);
    this.blocksSubject.next(this.blocks);

    this.struct.push([block.text, 0, 0]);
    this.saveData();
  }

  deleteBlock(index: number | null): void {
    if (index === null || index === 0) {
      // Prevent deletion of the first block
      return;
    }

    if (index > 0 && this.blocks.length > index) {
      // Calculate total bars only once, as it's used multiple times
      const totalBars = this.calculateTotalBars(this.waveSurfer.getDuration(), this.bpm, this.time_signature);

      // Adjust the previous block's end percent to take up the space of the deleted block
      const prevBlock = this.blocks[index - 1];
      const currBlock = this.blocks[index];
      const prevEndDuration = (parseFloat(prevBlock.left) + parseFloat(prevBlock.width)) * totalBars / 100;
      const currEndDuration = (parseFloat(currBlock.left) + parseFloat(currBlock.width)) * totalBars / 100;

      // Update the previous block's endPercent to include the current block's range
      prevBlock.endPercent = (currEndDuration / totalBars) * 100;
      prevBlock.width = `${prevBlock.endPercent - parseFloat(prevBlock.left)}%`;

      // Remove the current block
      this.blocks.splice(index, 1);
    }

    // Update observable to reflect changes
    this.blocksSubject.next(this.blocks);

    // Update struct accordingly and save changes
    this.struct.splice(index, 1);
    this.saveData();
  }

  moveLeft(index: number, cursorPosition: number): void {
    if (index === 0 || index >= this.blocks.length) {
      // Cannot move the first block's start or non-existent block
      return;
    }

    const currentBlock = this.blocks[index];
    const nextBlock = this.blocks[index + 1];

    console.log('diff next: ', (parseFloat(nextBlock.left) - cursorPosition));
    nextBlock.width = `${parseFloat(nextBlock.width) + (parseFloat(nextBlock.left) - cursorPosition)}%`;
    nextBlock.left = `${cursorPosition}%`;
    currentBlock.width = `${parseFloat(currentBlock.width) - (cursorPosition - parseFloat(currentBlock.left))}%`;

    // Update observable and UI
    this.blocksSubject.next(this.blocks);
    this.saveData(); // Persist changes
  }

  moveRight(index: number, cursorPosition: number): void {
    if (index === 0 || index >= this.blocks.length) {
      // No previous block to move its end
      return;
    }

    const currentBlock = this.blocks[index];
    const prevBlock = this.blocks[index - 1];
    const minEnd = parseFloat(currentBlock.left); // Previous block cannot end after the current block starts


    currentBlock.width = `${parseFloat(currentBlock.width) - (cursorPosition - parseFloat(currentBlock.left))}%`;
    currentBlock.left = `${cursorPosition}%`;

    // if (cursorPosition > minEnd) {
    //   cursorPosition = minEnd;
    // }

    console.log('currentBlock: ', currentBlock);
    console.log('prevBlock: ', prevBlock);
    console.log('minEnd: ', minEnd);

    prevBlock.endPercent = cursorPosition;
    prevBlock.width = `${cursorPosition - parseFloat(prevBlock.left)}%`;

    console.log('prevBlock new: ', prevBlock);

    // Update observable and UI
    this.blocksSubject.next(this.blocks);
    this.saveData(); // Persist changes
  }

  performAction(action: string) {
    console.log(action);
    switch (action) {
      case 'split':
        this.splitBlock(this.activeBlockIndex);
        break;
      case 'moveLeft':
        this.moveLeft(this.activeBlockIndex!, this.clickPercent);
        break;
      case 'moveRight':
        this.moveRight(this.activeBlockIndex!, this.clickPercent);
        break;
      case 'delete':
        this.deleteBlock(this.activeBlockIndex);
        break;
      default:
        console.error('Unknown action');
    }
    this.isContextMenuVisible = false;
  }

  openContextMenu(event: MouseEvent) {
    event.preventDefault(); // Prevent the default context menu
    this.contextMenuPosition = {x: event.clientX, y: event.clientY};
    this.isContextMenuVisible = true;
    this.activeBlockIndex = this.getBlockIndexAtPosition(event);

    console.log('getBlockIndexAtPosition: ', this.getBlockIndexAtPosition(event));
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (!this.waveformEl.nativeElement.contains(event.target as Node)) {
      this.isContextMenuVisible = false;
      this.activeBlockIndex = null;
      this.clickPercent = 0;
    }
  }

  protected override afterViewInit() {
    this.httpClient.get(`https://sketch-upload.session42.xyz/get-struct-and-file?sketch_id=667bb4764f5d55f13d096125`)
      .subscribe((data: any) => {
        console.log('data: ', data);
        this.initWaveSurfer(data.body['s3_url']);
        this.struct = JSON.parse(data.body['struct']);
        this.time_signature = data.body['time_signature'];
        this.bpm = data.body['bpm'];
      });
    this.renderer.listen('window', 'click', (event: Event) => this.onClickOutside(event));
  }
}
