import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VirtualQueueService } from '../../services/virtual-queue.service';
import { QueueInfo } from '../../interfaces/queue-Info';
import { ToastService } from '../../services/toast.service';
import { UserStateService } from '../../services/user-state.service';
import { LoadingService } from '../../services/loading.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ModalService } from '../../services/modal.service';
@UntilDestroy({checkProperties: true})
@Component({
  selector: 'sbz-management-queue',
  templateUrl: './management-queue.component.html',
  styleUrl: './management-queue.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManagementQueueComponent implements OnInit {
  public editSettings: boolean = false;
  public editUserId: string = '';
  public users: any = [];
  public itemsPerPage: number = 25;
  public currentPage: number = 1;
  public queueInfo!: QueueInfo;
  public grandAccess: {from: number, to: number} = {
    from: 0,
    to: 0
  };
  public bufferQueue: number = 0;
  public sendEmail: boolean = false;
  public usersPerPage: number = 15;
  constructor(
    private activatedRoute: ActivatedRoute,
    private virtualQueueService: VirtualQueueService,
    private cdr: ChangeDetectorRef,
    private toastService: ToastService,
    private userStateService: UserStateService,
    private loadingService: LoadingService,
    private modalService: ModalService
  ) {
  }
  get paginatedUsers() {
    const start = (this.currentPage - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;
    return this.users.slice(start, end);
  }
  ngOnInit(): void {
    this.activatedRoute.data.subscribe((res: any) => {
      this.users = this._sortUsers(res.users.data);
    });
    this.virtualQueueService.getQueueInfo().subscribe((res: {code: number, data: QueueInfo, success: boolean}) => {
      this.queueInfo = res.data;
      this._calculateTotalQueue();
      this.cdr.detectChanges();
    });
  }
  saveInfo(): void {
    this.loadingService.toggleLoading(true);
    this.queueInfo.bufferQueue = this.bufferQueue;
    this.virtualQueueService.setQueueInfo(this.queueInfo).subscribe({
      next: () => {
        this.toastService.showToast('Qeueu setting successfully saved!', 'success');
        this.editSettings = false;
        this.cdr.detectChanges();
        this.loadingService.toggleLoading(false);

      },
      error: () => {
        this.toastService.showToast('Somthing wrong!', 'error');
      }
    })
  }
  updateUserWhiteList(user: any, access: boolean): void {
    if(access) {
      user.customAttributes.isWhiteList = false;
      user.customAttributes.queuePosition = 0;
    }
    this.userStateService.updateUserWhiteList(user, this.sendEmail, access)
    .subscribe({
      next: (res: {code: number, data: any[], success: boolean}) => {
        this.users = this._sortUsers(res.data);
        this.toastService.showToast(`${user.name} successfully updated!`, 'success');
        this.virtualQueueService.getQueueInfo().subscribe((res: {code: number, data: QueueInfo, success: boolean}) => {
          this.queueInfo = res.data;
          this._calculateTotalQueue();
          this.cdr.detectChanges();
        });
      },
      error: (err: any) => {
        this.modalService.openErrorModal('We could not update user info. Please try again later.');
      },
      complete: () => {
        this.loadingService.toggleLoading(false);
      }
    });;
    this.editUserId = '';
  }
  grandAccesses() {
    this.userStateService.grandAccesses(this.grandAccess, this.sendEmail)
    .subscribe({
      next: (res: {code: number, data: any[], success: boolean}) => {
        console.log(res);
        this.toastService.showToast(`Successfully updated!`, 'success');
        this.users = this._sortUsers(res.data);
         this.virtualQueueService.getQueueInfo().subscribe((res: {code: number, data: QueueInfo, success: boolean}) => {
          this.queueInfo = res.data;
          this._calculateTotalQueue();
          this.cdr.detectChanges();
        });
      },
      error: (err: any) => {
        this.modalService.openErrorModal('We could not update user info. Please try again later.');
      },
      complete: () => {
        this.loadingService.toggleLoading(false);
      }
    });
  }
  changePage(page: number) {
    this.currentPage = page;
  }
  _calculateTotalQueue():void {
    this.bufferQueue = this.queueInfo.bufferQueue + Math.round(this.queueInfo.bufferQueue * (this.queueInfo.randomFactor / 100));
  }
  _sortUsers(users: any[]): any[] {
    return users.sort((a:any,b:any) => {
      if(!a.customAttributes.queuePosition && !b.customAttributes.queuePosition) return -1;
      return a.customAttributes.queuePosition < b.customAttributes.queuePosition ? -1 : 1;
    });
  }
}
