import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {BaseChatComponent} from '../base.chat.component';
import {BehaviorSubject} from 'rxjs';
import {ModalService} from '../../../../services/modal.service';
import {ChatService} from '../../../../services/chat.service';
import {AudioService} from '../../../../services/audio.service';
import {UserStateService} from '../../../../services/user-state.service';

@Component({
  selector: 'sbz-start-session',
  templateUrl: './chat-start-session.component.html',
  styleUrl: './chat-start-session.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatStartSessionComponent extends BaseChatComponent {
  fileName$ = new BehaviorSubject<string | undefined>(undefined);

  constructor(modalService: ModalService,
              chatService: ChatService,
              audioService: AudioService,
              userStateService: UserStateService) {
    super(chatService, audioService, userStateService, modalService);
  }

  startFileUpload() {
    this.userStateService.startFileUpload()
      .then((file) => {
        this.fileName$.next(file.name);
        // this.audioService.createAudioFileFromSketch(URL.createObjectURL(file));
        if (!this.userStateService.user$.getValue()) {
          this.modalService.openErrorModal('You have to be a registered user and you have to be logged in.');
          return;
        }

        this.goToNext('prompt-input');
        // this.userStateService.uploadFile(file)
        //   .subscribe({
        //     next: (data: any) => {
        //       this.audioService.sketchId = data.body.sketch_id;
        //     },
        //     error: (event: any) => {
        //       this.modalService.openErrorModal('We could not upload your song. Please try again.');
        //       this.goToNext('start-session');
        //     },
        //   });
      })
      .catch(err => {
      });
  }
}
