<nav>
  <div class="nav-bar-container">
    <a [routerLink]="'/'">
      <img alt="logo" fill ngSrc="assets/images/logo.webp" priority>
    </a>
    <div class="flex order-2 justify-end w-auto bg-transparent border-transparent">
      <button [attr.aria-expanded]="isMenuOpen" [class.opened]="isMenuOpen"
              aria-label="Main Menu"
              class="menu-button bg-transparent focus:outline-none border-transparent text-center inline-flex items-center !shadow-none"
              type="button">
        @if (user$ | async) {
          <div class="user-image" (click)="toggleMenu()">
            <img class="rounded-full" fill [ngSrc]="(user$ | async)!.pictureUrl" alt="Rounded avatar"
                 referrerpolicy="no-referrer">
          </div>
        } @else {
          <span class="menu-button-text" routerLink="/login">LOGIN</span>
        }
        <svg (click)="toggleMenu()" [ngClass]="{'open-animation': isMenuOpen, 'close-animation': !isMenuOpen}"
             viewBox="0 0 24 24"
             xmlns="http://www.w3.org/2000/svg">
          <path d="M25.157 14.484H2.04a2.04 2.04 0 1 1 0-4.079h23.117a2.04 2.04 0 1 1 0
          4.079zm0-9.517H2.04a2.04 2.04 0 1 1 0-4.079h23.117a2.04 2.04 0 1 1 0 4.079zM2.04 19.921h23.117a2.04
           2.04 0 0 1 0 4.08H2.04a2.04 2.04 0 0 1 0-4.08z" fill-rule="evenodd"/>
        </svg>
      </button>

    </div>
  </div>
</nav>

<div [ngClass]="{'blur-md opacity-[.4] pointer-events-none': isMenuOpen}"
     class="relative transition-all main-content max-w-[1920px]" id="main-content">
  <sbz-robot></sbz-robot>
  <div [ngClass]="{'blur-md opacity-[.8] pointer-events-none': isLoading$ | async}" class="router-container">
    <router-outlet></router-outlet>
  </div>
</div>

@if (isMenuOpen) {
  <div @slideMenu class="menu-container flex flex-col" (outsideClick)="toggleMenu()" (click)="toggleMenu()">
    @if (user$ | async) {
      <a [routerLink]="'/'">User Profile</a>
    }
    @if ((user$ | async); as user) {
      @if (!user.isWhiteList) {
        <a [routerLink]="'/subscribe'">Pricing</a>
      }
    }
    <a [routerLink]="'/about'">About Us</a>
    <a [routerLink]="'/contact'">Contact Us</a>
    <a [routerLink]="'/help'">Help and Support</a>
    @if(isAdmin) {
      <a [routerLink]="'/management-queue'">Manage queue</a>
    }
    @if(showQueue) {
      <a [routerLink]="'/queue'">Queue</a>
    }
    <a href="https://discord.com/channels/1239253617501012178/1251928799105388544" target="_blank">User community</a>
    @if (user$ | async) {
      <a [routerLink]="'/'" (click)="logout()">Logout</a>
    }
  </div>
}
