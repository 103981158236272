import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AudioService {
  sketchId = '';
  audioFileFromSketch: HTMLAudioElement | null = null;
  private currentAudio: HTMLAudioElement | null = null;

  constructor() {
  }

  createAudioFileFromSketch(fileURL: string) {
    this.audioFileFromSketch = new Audio(fileURL);
  }

  playSong(songName: string, playSketch = false): void {
    // Stop the current song if one is playing
    if (this.currentAudio) {
      this.currentAudio.pause();
      this.currentAudio.currentTime = 0;
    }

    // Create a new audio element
    this.currentAudio = playSketch ? this.audioFileFromSketch! : new Audio(`assets/music/${songName}.mp3`);

    // Play the new song
    this.currentAudio.play().catch(error => {
      console.error('Error playing the audio file:', error);
    });
  }

  stopSong(): void {
    if (this.currentAudio) {
      this.currentAudio.pause();
      this.currentAudio.currentTime = 0;
      this.currentAudio = null;
    }
  }

  togglePlayPause(): void {
    if (this.currentAudio) {
      if (this.currentAudio.paused) {
        this.currentAudio.play().catch(error => {
          console.error('Error resuming the audio file:', error);
        });
      } else {
        this.currentAudio.pause();
      }
    }
  }
}
