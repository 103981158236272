<p class="chat-text">
  Last piece of the puzzle – your lyrics! This helps me fine-tune the
  production to match your message.
</p>

<div class="lyrics-container">
  <textarea (input)="adjustHeight($event)" [(ngModel)]="lyrics"
            [readonly]="!currentActiveComponent"
            rows="6"></textarea>

  <button (click)="submitLyrics()"
          [class.chat-button-generic-disabled]="!currentActiveComponent"
          class="gradient-purple-button">
    Send
  </button>
</div>
